@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.application {
    margin-top: 120px;
    background-color: #f2f2f2;
    padding-top: 75px;
    padding-bottom: 55px;
}
.application-container {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
/* .application-img-container {
    margin-right: 110px !important;
} */
.application-container-right {
    margin-left: 80px;
}
.application-title {
    color: #333;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
}
.application-description {
    max-width: 500px;
    color: var(--black);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
}
.download-buttons {
    display: flex;
    margin-top: 23px !important;
}
.google-play-icon {
    margin-right: 24px !important;
}

.application-container-left-side {
    animation-name: leftSide;
    animation-duration: 0.7s;
}
.application-container-right-side {
    animation-name: rightSide;
    animation-duration: 0.7s;
}
.application-container-left-side-start {
    opacity: 0;
}
.application-container-right-side-start {
    opacity: 0;
}
