
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .instructor-support-banner {
        height: 30vh;
        min-height: 160px;
        max-height: 250px;
    }
    .banner-title {
        transform: translateY(-10%);
        font-size: 90px;
    }
    .support-navigation-container {
        display: block;
        position: absolute;
        z-index: 900;
        transform: translateY(48px);
        min-width: 250px;
    }
    .support-navigation {
        opacity: 0;
    }
    .support-down-arrow {
        display: block;
    }
    .support-tablet-navigation {
        display: block;
        margin-top: 8px;
    }
    .support-down-arrow-icon {
        display: none;
    }
    .support-tablet-arrow {
        display: block;
    }
    .support-footer {
        margin-top: 60px;
    }

    .support-nav-tablet-anim {
        transition: opacity 1s ease;
        transform: scaleX(100%);
        animation-name: supportNavTabletAnim;
        animation-duration: 0.3s;
        opacity: 1;
    }
    /* .support-tablet-navigation {
        display: block;
    } */
    .support-nav-tablet-anim-close {
        transition: opacity 1s ease-out;
        animation-name: supportNavTabletAnimClose;
        animation-duration: 0.3s;
        opacity: 0;
    }

    @keyframes supportNavTabletAnim {
        0% {
            transform: scaleX(0);
            transform-origin: left;
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            transform: scaleX(100%);
            transform-origin: left;
        }
    }
    
    @keyframes supportNavTabletAnimClose {
        0% {
            transform: scaleX(100%);
            transform-origin: left;
            opacity: 1;
        }
        99% {
            opacity: 0;
        }
        100% {
            transform: scaleX(0);
            transform-origin: left;
        }
    }
}
