
@keyframes rightSide {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes leftSide {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes SubscriptionLeftSide {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translate(0,-55px);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(100%);
        opacity: 0.05;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes PackagesImageFadeIn {
    from {
        transform: translateY(100%);
        opacity: 0.05;
    }
    to {
        opacity: 1;
        transform: translate(0%,-60px);
    }
}

@keyframes titleAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes howDoesItWorkRightSide {
    from {
        transform: translate(100%,-15%);
        opacity: 0;
    }
    to {
        transform: translate(-20%,-55%);
        opacity: 1;
    }
}

@keyframes howDoesItWorkLeftSide {
    from {
        transform: translate(10%,300%);
        opacity: 0;
    }
    to {
        transform: translate(20%,70%);
        opacity: 1;
    }
}
