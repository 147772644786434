.read-speak {
    display: flex;
    background: #EDF9F0;
    border: 1px solid #CBE9D2;
    border-radius: 18px;
    padding: 2px 8px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
}

.read-speak-offline{
    display: flex;
    background: #ffd2c9;
    border: 1px solid #e93f3f;
    border-radius: 18px;
    padding: 2px 8px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
}

.online-wrapper {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    color: #287D3C;
    align-items: center;
}

.offline-wrapper {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    color: #b32727;
    align-items: center;
}

.name-wrapper b {
    color: #45494D;
}

.name-wrapper {
    font-size: 16px;
    font-weight: 700;
    color: #777777;
}

body {
    font-family: 'Nunito';
}

.online-wrapper>div {
    margin-right: 10px;
}


.offline-wrapper>div {
    margin-right: 10px;
}

.offline-wrapper form .custom-control-input:checked~.custom-control-label::before {
    background: #b33c27;
    border-color: #b32727;
}

.online-wrapper form .custom-control-input:checked~.custom-control-label::before {
    background: #287D3C;
    border-color: #287D3C;
}

.tutor-dashboard-top-section .card {
    box-shadow: 0px 15px 20px rgba(141, 141, 141, 0.25);
    border-radius: 16px;
    border: none;
}

.tutor-dashboard-top-section .card .card-body h5 {
    color: #777777;
    font-weight: bold;
    font-size: 14px;
}

.tutor-dashboard-top-section .card .card-body {
    padding: 12px;
}

.tutor-dashboard-top-section .small-card.card {
    width: 25%;
}

.tutor-dashboard-top-section .large-card.card {
    width: 60%;
    margin: 0 20px;
}

.small-card.card {
    width: 25%;
}

.large-card.card {
    width: 50%;
    margin: 0 20px;
}

.rank-card {
    background: #F2F2F2;
    border-radius: 16px;
    width: 100%;
    padding: 15px 10px;
}

.rank-card h3 {
    color: #45494D;
    font-weight: bold;
    font-size: 32px;
    margin: 10px 0;
}

.rank-card h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #45494D;
    opacity: 0.7;
    margin: 0;
}

.rank-card-wrapper {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.rank-main-wrapper {
    margin: 0 -10px;
}

.summary-small-card {
    background: #F86F53;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 11px;
    border-radius: 16px 0px 0px 16px;
    color: #fff;
}

.summary-large-card {
    flex-grow: 1;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-small-card.last-card {
    border-radius: 0px 16px 16px 0px;
}

.yak-card .app-wrapper h4 {
    color: #52575C;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.yak-card .app-wrapper p {
    margin: 0;
    color: #929598;
    font-weight: 600;
    font-size: 14px;
}

.yak-card {
    padding: 10px 0;
}

.app-wrapper {
    margin-bottom: 15px;
}

.yak-card .app-wrapper .badge.danger {
    background: #FDD9D1;
    border-radius: 3px;
    color: #F86F53;
    font-weight: 600;
    font-size: 13px;
}

.yak-card .app-wrapper h4 {
    color: #52575C;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.yak-card .app-wrapper p {
    margin: 0;
    color: #929598;
    font-weight: 600;
    font-size: 14px;
}

.yak-card {
    padding: 10px 0;
}

.app-wrapper {
    margin-bottom: 15px;
}

.yak-card .app-wrapper .badge.danger {
    background: #FDD9D1;
    border-radius: 3px;
    color: #F86F53;
    font-weight: 600;
    font-size: 13px;
    padding: 0x;
}

.summary-small-card p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 2px 0;
}

.card-content h4 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.8;
    margin: 0;
}

.card-content.total * {
    opacity: 1;
}

.card-content {
    margin-bottom: 5px;
}

.card-content p {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.8;
}



.chart-content .blue {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #5389F8;
}

.chart-content .orange {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #F86F53;
}

.chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-content h3 {
    width: 100%;
    max-width: 100px;
}

h2.dash-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #45494D;
    margin-top: 30px;
    float: left;
    width: 100%;
}

.calanderActionButton {
    justify-content: flex-end;
    position: absolute;
    right: 20px;
    margin: auto;
}

.calanderActionButton .btn-danger {
    background: #F86F53;
    border-radius: 8px;
    margin-left: 10px;
    border-color: #F86F53;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
}

.calanderActionButton .btn-outline-secondary {
    background: #fff;
    border-radius: 8px;
    margin-left: 10px;
    border-color: #F86F53;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    color: #F86F53;
}

.calanderActionButton button {
    min-width: 210px;
}


.fc-header-toolbar .fc-toolbar-chunk>div {
    display: flex;
}

.fc-header-toolbar .fc-toolbar-chunk>div .fc-toolbar-title {
    background: #F2F2F2;
    font-weight: bold;
    line-height: 22px;
    color: #52575C;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330px;
}

.fc-header-toolbar .fc-toolbar-chunk>div button {
    background: #5389F8;
    border-color: #5389F8;
    position: relative;
    z-index: 3;
}

.fc-header-toolbar .fc-toolbar-chunk>div .fc-today-button {
    margin-right: 20px;
}

.fc-header-toolbar .fc-toolbar-chunk>div button.fc-prev-button {
    border-radius: 4px 0px 0px 4px;
}

.fc-header-toolbar .fc-toolbar-chunk>div button.fc-next-button {
    border-radius: 0px 4px 4px 0px;
}


/* .date-picker input {
    background: url(../../assets/calender.svg);
} */


.quickReservation .modal-header {
    background: #fff;
    border: none;
    padding: 20px 20px;
}

.quickReservation .modal-header .h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #426FCD;
}

.quickReservation .modal-header .close {
    color: #45494D;
    opacity: 1;
    font-size: 26px;
    font-weight: 900;
    padding: 11px 10px 0px 0;
}

.quickReservation .form-control {
    border: 1px solid #CACCCF;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    height: 48px;
    margin-bottom: 15px;
}

.quickReservation .modal-dialog {
    max-width: 450px;
}

.quickReservation .modal-content {
    padding: 10px;
}

.res-action-wrapper .button-wrapper {
    display: flex;
    flex-grow: 1;
}

.res-action-wrapper .button-wrapper .btn-danger {
    background: #F86F53;
    border-radius: 8px;
    border-color: #F86F53;
    height: 48px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.booked-time h5 {
    color: #929598;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
}

.res-action-button .btn-primary {
    background: #5389F8;
    border-radius: 25px;
    border-color: #5389F8;
    height: 48px;
}

.res-action-button .btn-light {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
}

.time-slot-for-available-times {
    border-bottom: 1px solid #CACCCF;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    /* width: 20%; */
}

.time-slot-for-available-times .time {
    color: #45494D;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.time-slot .quickbook {
    cursor: pointer;
}

.confirmationModal {
    box-shadow: 0px 15px 20px rgba(141, 141, 141, 0.25);
    border-radius: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 9;
    height: 200px;
    padding: 20px;
    margin: auto;
    left: 0;
    right: 0;
    width: 350px;
}

.confirmationModal .img-wrapper img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 24px;
}

.confirmation-content>h5 {
    font-weight: bold;
    font-size: 19px;
    line-height: 18px;
    color: #52575C;
    margin-top: 10px;
    margin-bottom: 0;
}

.confirmation-content {
    margin-left: 10px;
}

.confirmation-content .date {
    display: flex;
}

.confirmation-content .date>h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #929598;
}

.confirmation-content .date>span {
    background: #FFECE8;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    color: #F86F53;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    height: 20px;
}

.confirmation-content .day {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #929598;
    margin-top: 10px;
}

.confirmation-actions button {
    background: #F2F2F2;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #45494D;
    margin: 0 5px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.fc-timegrid-slot-label-cushion {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #52575C;
}

.fc-day-today {
    background: #FFF !important;
    /* border: none !important; */
    border-top: 1px solid #F00 !important;
    font-weight: bold;
}

.confirmation-actions {
    display: flex;
    margin: 20px -5px 0;
}

.confirmationModal .close {
    color: #696a6a;
    opacity: 1;
    font-size: 20px;
    font-weight: 700;
}

.fc-col-header-cell {
    height: 80px;
    vertical-align: middle !important;
}

.fc-col-header-cell a {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #45494D;
}




.custom-switch {
    padding-left: 3.25rem;
}

.custom-control {
    min-height: 2.5rem;
    padding-left: 3.5rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 2rem;
    height: 3.25rem;
    opacity: 0;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-switch .custom-control-label::before {
    left: -3.6rem;
    width: 3.75rem;
    pointer-events: all;
    border-radius: 2.5rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 2rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.75rem);
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-3.6rem + 2px);
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    background-color: #adb5bd;
    border-radius: 1.5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.calender-main-wrapper.card {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    background: #fff;
    border: 1px solid transparent;
}

.calender-main-wrapper.card .card-body {
    padding: 20px 0;
}

.calender-main-wrapper.card .card-body .fc-header-toolbar {
    padding-left: 15px;
}

.timeWrapper {
    display: flex;
    padding: 19px 0 0 25px;
    float: left;
    width: 100%;
}

.timeWrapper .time-inner p {
    font-size: 13px;
    line-height: 18px;
    color: #52575C;
    margin: 0 0 0px 5px;
    max-width: 130px;
    line-height: 16px;
}

.timeWrapper .time-inner .box {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.timeWrapper .time-inner {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.box.fifMin {
    background: #E5EDFE;
}
.box.availableTime {
    background: #FAD0C6;
}

.box.thirthyMin {
    background: #D4E1FD;
}

.box.sixtyMon {
    background: #BAD0FC;
}

.box.moremin {
    background: #CACCCF
}

.calender-main-wrapper .calender-wrapper .fc-timegrid-slot {
    height: 90px !important;
}
.fc-header-toolbar .fc-toolbar-chunk>div button.fc-today-button {
    width: 150px;
    text-transform: capitalize;
}

