.report-edit form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin: 20px;
}

.report-edit form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.report-edit form button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #E55F44; /* Green */
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.report-edit form button:hover {
    background-color: #E55F44;
}

.report-edit form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
}

.report-edit form span {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    color: #333;
    width: 100%;
}

.report-edit-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: calc(100vh - 100px);
    padding-top: 50px;
    padding: 20px;
}

.report-view-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: calc(100vh - 100px);
    padding-top: 50px;
    padding: 20px;
}
.report-view-group  {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.report-table {
    /*display: block;*/
    /*overflow-x: auto;*/
    /*table-layout: fixed;*/
    /*white-space: nowrap;*/
    width: 100%;
    border-collapse: collapse;
    display: block;
    max-width: -moz-fit-content;
    /*max-width: fit-content;*/
    /*margin: 0 auto;*/
    overflow-x: auto;

}

.report-table th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    background-color: #E55F44;
    color: white;
    border: 1px solid #ddd;
    padding-left: 4px;
    padding-right: 4px;
}
.report-table td {
padding: 4px;
    border: 1px solid #ddd;
    text-align: left;
}
.report-table tr {
    padding-top: 12px;
    padding-bottom: 12px;
}
.center-text {
    text-align: center !important;
}

.report-row {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

.report-row:nth-child(even) {
    background-color: #f2f2f2;
}

.report-view-title {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--black)
}
.report-view-subtitle {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    display: block;
    margin-bottom: 40px;
}
