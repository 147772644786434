
@media only screen and (max-width: 720px) {
    .top-bar-status-btn-container {
        width: 100%;
        overflow-x: scroll;
    }
    .top-bar-status-btns {
        overflow-x: scroll;
        margin-right: 0;
        width: 400px;
    }
    .instructors-search-bar {
        width: 100% !important;
        transform: translateY(0);
    }
    .top-bar-input {
        display: block;
    }
}
