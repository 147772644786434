
@media only screen and (max-width: 720px) {
    .application-container {
        display: block;
        padding-bottom: 35px;
    }
    .application-img-container {
        width: 80vw;
        margin-top: -120px;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
    .application-container-right {
        width: 90vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .application-title {
        text-align: center;
        font-size: 32px;
        line-height: 44px;
        margin-top: 30px;
    }
    .application-description {
        text-align: center;
        width: 90vw !important;
    }
    .download-buttons {
        width: 90vw !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .google-play-icon {
        display: block;
        float: left;
        cursor: pointer;
    }
    .app-store-icon {
        display: block;
        float: right;
        cursor: pointer;
    }
}