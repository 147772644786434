@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-languages-container {
    width: 100%;
    margin-top: 25px;
}
.instructor-languages-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-languages-settings-container {
    margin-top: 11px;
    width: 100%;
}
.instructor-languages-settings-labels-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.instructor-languages-settings-label {
    text-align: left;
    color: #52575C;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    width: 31%;
    min-width: 200px;
}
.instructor-languages-setting-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    /* justify-content: space-between; */
}
.instructor-languages-setting-input-container {
    width: 31%;
    min-width: 200px;
    margin-right: 10px;

}
.instructor-languages-setting-action-container button {
    margin-right: 5px;
    align-items: center;
    margin-bottom: 1rem;
    /* min-width: 200px; */
}
.instructor-languages-setting-input {
    transform: translateY(-7px);
}
.instructor-languages-setting-sep {
    width: 100%;
    background-color: #CACCCF;
    margin-top: 30px;
}
