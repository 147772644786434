@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.student-instructors-page-header-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: flex-end;
    justify-content: space-between;
}
.student-instructors-page-header-title {
    text-align: left;
    color: #45494D;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.student-instructors-page-status-btns {
    display: flex;
    width: 600px;
}
.student-instructors-page-sep {
    height: 38px;
    width: 1px;
    background-color: #929598;
    margin-right: 8px;
}
.student-instructors-page-search-input-container {
    min-width: 290px;
    width: 35%;
}

/* Header All Options Component */

.header-all-options-container {
    width: 200px;
    height: 270px;
    background-color: #fff;
    position: absolute;
    transform: translateY(103%);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 900;
}
.header-all-options-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
