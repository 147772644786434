.student-instructors-page-card-container {
    width: 31.5%;
    min-width: 290px;
    height: 130px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
.student-instructors-page-card-container.subscription-summary {
    display: block;
    height: 300px;
    position: relative;
    top: 0;
    padding-top: 15px;
    z-index: 2;
}
.student-instructors-page-card-wrapper.subscription-summary {
    display: flex;
}
.student-instructors-page-card-container.first-card {
    box-shadow: none;
}
.student-instructors-page-card-content {
    margin-left: 22px;
    width: 60%;
}
.student-instructors-page-card-title {
    text-align: left;
    color: #929598;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
}
.student-instructors-page-card-name {
    text-align: left;
    color: #45494D;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    font-family: 'Nunito', sans-serif;
}
.student-instructors-page-card-call-duration {
    text-align: left;
    color: #45494D;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
}

.student-instructors-page-card-one-time {
    height: fit-content;
    text-align: left;
    color: #FFFFFF;
    background-color: #5287F4;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}

.student-instructors-page-card-date {
    text-align: left;
    color: #45494D;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
}
.student-instructors-page-card-btn {
    margin-top: 10px;
    width: 100%;
}
.student-instrcutors-page-level-container {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.student-instructors-page-level {
    height: 11px;
    width: 42px;
    border-radius: 8px;
    margin-right: 4px;
    background-color: #FDD9D1;
}
.student-instructors-page-level.filled {
    background-color: #F86F53;
}
.student-instructors-page-other-content-container {
    margin-top: 12px;
}
.student-instructors-page-down-arrow-container {
    position: relative;
    cursor: pointer;
    height: 24px;
    width: 24px;
    left: -3%;
    top: 80px;
}
.student-instructors-page-card-info {
    display: flex;
}
.student-instructors-page-nationality {
    margin-left: 7px;
    transform: translateY(5px);
}
.student-instructors-page-interview-date {
    display: flex;
    height: fit-content;
    width: fit-content;
    align-items: center;
}

.student-instructors-page-not-available-title {
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #929598;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.student-instrucotrs-page-not-available-desc{
    text-align: left;
    color: #F86F53;
    font-weight: 700;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 6px;
    font-size: 14px;
    cursor: pointer;
}

.student-instructors-page-card-next-session-unavailable {
    text-align: left;
    color: #929598;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
    width: 163px;
}
.student-instructors-page-card-next-session-unavailable-reservation{
    font-weight: 700;
    color: #F86F53;
    margin-left: 5px;
    cursor: pointer;
}

/* Card More Details */

.card-more-details-container {
    height: 141px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 24px;
    box-sizing: border-box;
    padding-left: 7px;
    display: flex;
}
.card-more-details-plan-container {
    height: 141px;
    width: 125px;
    background-color: #F2F2F2;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 18px 7px;
}
.card-more-details-plan-container-title {
    text-align: center;
    color: #929598;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
}
.card-more-details-plan-desc {
    text-align: center;
    color: #45494D;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 4px;
}
.card-more-details-plan-change button{
    text-align: center;
    align-items: center;
    color: #F86F53;
    font-weight: 700;
    line-height: 18px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 7px;
}
.card-more-details-info {
    height: 141px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-left: 8px;
}
.card-more-details-info-wrapper {
    height: fit-content;
    width: fit-content;
    display: block;
}
.card-more-details-info-wrapper-title {
    text-align: left;
    color: #929598;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.card-more-details-info-wrapper-desc {
    text-align: left;
    color: #45494D;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    font-family: 'Nunito', sans-serif;
    margin-top: 0;
    margin: 0;
    padding: 0;
}
