@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new-message-banner {
    margin-top: 120px;
}

.new-message-banner-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
    color: #45494D;
}

.new-message-banner-card {
    display: flex;
    justify-content: space-between;
}

.new-message-banner-item-wrapper {
    height: 345px;
    width: 100%;
    padding: 3px;
    padding-bottom: 6px;
    margin-top: 30px;
}

.new-message-banner-card-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 27px;
    padding-bottom: 19px;
}

.new-message-banner-card-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
    font-family: 'Nunito', sans-serif;
    margin: 0;
    margin-top: 20px;
}

.new-message-banner-card-description {
    text-align: center;
    color: var(--darkGray);
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.new-message-banner-item-wrapper:hover {
    background-color: #fff;
    border-color: #F86F53;
    border-style: solid;
    border-width: 3px;
    border-bottom-width: 6px;
    padding: 0;
    border-radius: 19px;
}

.new-message-banner-card-item:hover .new-message-banner-card-title {
    transition: color 0.3s ease;
    color: #F86F53;
}

.new-message-banner-container {
    animation-name: fadeIn;
    animation-duration: 0.7s;
}

.new-message-banner-content {
    padding-top: 16px;
    /* background-color: #4a3632; */
}

.new-message-banner-image-container {
    padding-top: 16px;
    /* background-color: #DD0000; */
}

.new-message-banner-container {
    height: 100%;
}

.new-message-banner-container-row {
    height: 100%;
}

.new-message-banner-container {
    /* height: 560px; */
    background-image: url(../../assets/Home/message-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;

}


.new-message-banner-container-item-container {
    padding: 12px 18px !important;
    gap: 2px;
    /* width: 444px;
    height: 103px; */
    background: #F2F2F2;
    border-radius: 12px;
    margin-bottom: 21px;
}


.new-message-banner-container-item-container-item-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
}