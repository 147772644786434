
@media only screen and (max-width: 991px) and (min-width: 767px) {

    .profile-settings-level-wrapper {
        height: fit-content;
    }
    .profile-settings-intrests-wrapper {
        height: 320px;
    }
    .profile-settings-goals-wrapper {
        height: 250px;
    }
}
