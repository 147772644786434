.content
{
    max-width: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container
{
    align-self: center;
    width: 100%;
}

.video-recorder-container
{
    align-self: center;
    width: 560px;
    height: 560px;
}
.button-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
.button-container button
{
    margin-left : 16px
}

.button-danger
{
    background-color: red;
    border-color: red;
}
.button-danger :hover
{
    background-color: brown;
}

