
@media only screen and (max-width: 720px) {
    .reservation-top-bar-container {
        display: block;
    }
    .reservation-schedule {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }
    .reservation-call-duration {
        width: 100%;
    }
    .reservation-schedule-title {
        font-size: 16px;
    }
    .reservation-schedule-calendar {
        width: 130px;
    }
    .reservation-schedule-calendar>p {
        font-size: 14px;
    }
    .calendar-image {
        margin-left: 5px;
        height: 20px;
        width: 20px;
    }
    .reservation-schedule-time {
        width: 95px;
    }
}

@media only screen and (max-width: 340px) {
    .reservation-schedule-seperator {
        display: none;
    }
    .reservation-schedule-time {
        margin-left: 7px;
        width: 105px;
        margin-right: -5px;
    }
}
