
@media only screen and (max-width: 720px) {
    .mobile-options {
        height: 311px;
        width: 56px;
        background-color: #292929;
        border-radius: 30px;
        box-sizing: border-box;
        padding: 6px;
        padding-left: 6.5px;
        position: absolute;
        right: 8px;
        bottom: 15px;
        display: block;
        z-index: 5;
    }
    .mobile-option-icon {
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        margin: 0;
        padding: 0;
        margin-bottom: 7px;
    }
    .mobile-option-icon.phone {
        background-color: #F52C33;
    }
    .mobile-option-icon.options {
        background-color: #F86F53;
    }
}
