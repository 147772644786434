
@media only screen and (max-width: 720px) {
    .payment-form {
        padding: 24px 8px;
    }
    .payment-methods {
        display: none;
    }
    .payment-form-title {
        font-size: 30px;
    }
    .credit-card-info {
        display: block;
        margin-top: 15px;
        height: 150px;
    }
    .credit-card-holder {
        width: 100%;
    }
    .credit-card-extra-info {
        margin-top: 15px;
        width: 48%;
        margin-right: 2%;
    }
    .credit-card-holder,
    .credit-card-extra-info {
        float: left;
    }
    .billing-adress-info {
        margin-top: 30px;
    }
    .payment-go-back {
        width: fit-content;
        transform: translateX(-20px);
        margin: 60px auto 0;
    }
}
