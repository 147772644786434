@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.packages-total-bill {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
    background-color: #fff;
    border-radius: 25px;
}
.packages-total-bill-title {
    text-align: left;
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
    font-weight: 700;
}
.package-total-bill-feaures {
    margin-top: 30px;
}
.package-total-bill-feaures p {
    width: 100%;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.package-total-bill-feaures p:nth-child(1) {
    color: #929598;
}
.package-total-bill-feaures p:nth-child(2) {
    color: #52575C;
}
.package-total-bill-feaures p:nth-child(3) {
    color: #5389F8;
    font-weight: 700;
}
.package-total-bill-feaures p:nth-child(4) {
    color: #5389F8;
    font-weight: 700;
}
.packages-total-bill-sep {
    width: 1px solid #CACCCF;
    background-color: #CACCCF;
    margin-top: 10px;
}
.package-total-bill-info {
    margin-top: 10px;
}
.package-total-bill-info p{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}
.package-total-bill-info p:nth-child(1) {
    color: #52575C;
}
.package-total-bill-info p:nth-child(2) {
    color: #929598;
    font-weight: 400;
    justify-content: flex-end;
}
.package-total-bill-info p:nth-child(2) span {
    margin-left: 15px;
}
.package-total-bill-monthly-payment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #52575C;
}
.package-total-bill-monthly-payment span:nth-child(1) span {
    font-style: italic;
}
.package-total-bill-promo-code {
    text-align: center;
    color: #F86F53;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    font-weight: 700;
    text-decoration: underline;
    padding-bottom: 15px;
    cursor: pointer;
}
.package-total-bill-purchase-btn-container {
    margin-top: 30px;
}
.package-total-bill-link {
    margin: 0;
    padding: 0;
    margin-top: 6px;
    text-align: center;
    color: #929598;
    font-size: 14px;
    min-height: 20px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}
.form-terms-condition {
    text-align: left;
    color: #929598;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
    margin-top: 30px;
    font-weight: 400;
}
.promo-input-container {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    opacity: 0;
}
.promo-input {
    width: 60%;
}
.promo-btn {
    width: 35%;
}

.promo-code-link-anim {
    animation-name: PromoCodeLinkAnim;
    opacity: 0;
    animation-duration: 0.3s;
}
.promo-code-anim {
    animation-name: PromoCodeAnim;
    opacity: 1;
    animation-duration: 0.5s;
    /* animation-delay: 0.4s; */
}

@keyframes PromoCodeLinkAnim {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes PromoCodeAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
