
@media only screen and (max-width: 720px) {
    .support-questions-title {
        display: none;
    }
    .category-title-1 {
        margin-top: 0 !important;
    }
    .intsructor-questions-accordion-container {
        margin-bottom: 50px;
    }
    .support-question-title,
    .support-question-parag {
        font-size: 12px;
    }
    .support-question-title {
        padding: 18px 9px !important;
    }
    .support-question-parag {
        padding: 9px !important;
    }
}
