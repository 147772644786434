
.chatroom-message-opponent-container {
    width: 90%;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    /* justify-content: flex-end; */
}
.chatroom-message-opponent-circle {
    height: 16px;
    width: 16px;
    background-color: #F2F2F2;
    border-radius: 50%;
}
.chatroom-messages-opponent-wrapper {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
}
.chatroom-message-opponent-content-info-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
}
.chatroom-message-opponent-content {
    padding: 8px;
    border-radius: 8px;
    background-color: #F2F2F2;
    max-width: 185px;
}
.chatroom-message-opponent-content-text {
    text-align: left;
    color: #52575C;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    word-break: break-all;
    white-space: normal;
}
.chatroom-message-opponent-time {
    text-align: right;
    color: #CACCCF;
    font-size: 10px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-left: 6px;
}

