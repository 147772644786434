@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.profile-settings-title {
    text-align: left;
    color: #45494D;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.profile-settings-info {
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
}
.profile-settings-pic {
    height: 150px;
    width: 183px;
}
.profile-settings-pic-change-icon-container {
    height: 55px;
    width: 55px;
    background-color: #F86F53;
    border-radius: 50%;
    position: relative;
    left: 40%;
    transform: translate(-50%, -80%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.profile-settings-inputs {
    width: calc(100% - 200px);
}
.profile-settings-sep {
    margin-top: 30px;
    background-color: #CACCCF !important;
}
.profile-settings-level-container {
    margin-top: 30px;
    width: 100%;
}
.profile-settings-level-wrapper {
    width: 100%;
    margin-top: 15px;
    display: flex;
    height: 50px;
}
.profile-settings-intrests-wrapper {
    width: 100%;
    margin-top: 15px;
    display: block;
}
.profile-settings-goals-wrapper {
    width: 100%;
    margin-top: 15px;
    display: block;
}
.save-button-container {
    margin-top: 15px;
    width: 100%;
    display: flex;
}
.profile-settings-upload-image {

    transform: translate(-14px, 8px);
    background-color: rgba(0, 0, 0, 0);
    border: 0;
}
.profile-settings-upload-image-file {
    height: 100%;
    z-index: 100;
    transform: translateX(-80px);
    opacity: 0;
    cursor: pointer;
}
.profile-settings-upload-image-file input{
    height: 100px;
    width: 100px;
}
.profile-settings-upload-image-file label{
    display: none;
}
.profile-settings-image-cropper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.profile-settings-image-cropper-container {
    width: 600px;
}
.profile-settings-image-cropper-btns-container {
    display: flex;
    margin-bottom: 10px;
}
.profile-settings-image-cropper-btn {
    width: 200px;
    margin-top: 20px;
}
.profile-settings-image-cropper-btn:nth-child(1) {
    margin-right: 10px;
}
