
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .why-speakingline-title {
        font-size: 50px;
        margin-bottom: 40px !important;
    }
    .why-speakingline-cards {
        display: block;
    }
    .why-speakingline-cards-container {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-5%);
    }
    .why-speakingline-card-item-container {
        min-width: 330px;
        width: 42%;
        margin-bottom: 20px;
        display: block;
        float: right;
    }
}
