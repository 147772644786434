
@media only screen and (max-width: 960px) {
    .reservation-call-duration {
        width: 380px;
    }
    .reservation-schedule {
        width: 380px;
        margin-left: 15px;
    }
    .reservation-schedule-calendar {
        width: 135px;
        padding: 0 5px;
        font-size: 14px;
    }
    .reservation-schedule-time {
        width: 92px;
    }
}
