@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.student-instructors-page-top-bar-container {
    width: 100%;
    height: 130px !important;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: visible;
}

