@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*  Footer Component  */

footer {
    width: 100vw;
    height: 120px;
    background-color: #F86F53;
    box-sizing: border-box;
    padding-top: 15px;
}
.footer-container {
    height: 100%;
    width: 100%;
    background-color: #52575C;
}

.mainContent {
    min-height: calc(100vh - 208px) ;
    flex: 1;
}

/* Top Section */
.footer-top {
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-top-link {
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    font-family: 'Nunito', sans-serif;
}
.footer-top-link:hover {
    text-decoration: none;
    color: #AAA;
}
.whatsapp-link {
    margin-left: 2px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    font-family: 'Nunito', sans-serif;
}
.whatsapp-link:hover {
    text-decoration: none;
    color: #AAA;
}


/*  Bottom Section */
.footer-bottom {
    height: 45%;
    margin-bottom: 4%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    padding-top: 10px;
}
.footer-bottom * {
    color: #fff;
    cursor: pointer;
}
.footer-bottom-centered-links {
    display: flex;
}
.footer-bottom-centered-links p {
    margin-left: 15px;
}

/* Social Media Icons */
.footer-bottom-social-media {
    display: flex;
}
.footer-icon {
    margin-right: 15px;
    cursor: pointer;
}

.whatsapp-icon {
    width: 23px;
    height: 23px;
    align-self: center;
}

.chatText {
    margin-left: 5px;
    vertical-align: middle;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
}

.whatsapp-button-container
{
    /* border-radius: 999rem;
    bottom: 0;
    letter-spacing: 0.6px;
    font-size: 13px;
    font-weight: 700;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10000; */

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: poppins,sans-serif;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-width: 2px;
    padding: 8px 23px;
    background-color: #25D366;
    text-decoration: none !important;
    cursor: pointer;
    color: #fff;
    fill: #fff;
    border-radius: 999rem;
    letter-spacing: 0.6px;
    font-size: 13px;
    font-weight: 700;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10000;
}

a.whatsapp-button-container:hover {
    background-color: #075E54 !important;
}
