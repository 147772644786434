@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.regular-page-header {
    height: 200px;
    width: 100vw;
    background-color: #F86F53;
}
.regular-paga-header-container {
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.regular-page-header-title {
    text-align: left;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
}
.regular-page-header-subtitle {
    text-align: right;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.regular-page-content {
    margin-top: 33px;
    min-height: calc(100vh - 442px);
}
.regular-page-footer-container {
    margin-top: 90px;
}
