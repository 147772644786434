@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new_application {
    margin-top: 120px;
    background-color: rgba(210, 235, 252, 0.55);
    padding-top: 75px;
    padding-bottom: 55px;
}

.new_application-container {
    width: fit-content;
    /* display: flex; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

/* .new_application-img-container {
    margin-right: 110px !important;
} */
.new_application-container-right {
    /* margin-left: 80px; */
}

.new_application-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    display: flex;
    align-items: flex-end;
    color: #45494D;
}

.new_application-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    color: #45494D;
}

.new_download-buttons {
    display: flex;
    margin-top: 23px !important;
}

.new_google-play-icon {
    margin-right: 24px !important;
    cursor: pointer;
}

.new_app-store-icon {
    display: block;
    float: right;
    cursor: pointer;
}

.new_application-container-left-side {
    animation-name: leftSide;
    animation-duration: 0.7s;
}

.new_application-container-right-side {
    animation-name: rightSide;
    animation-duration: 0.7s;
}

.new_application-container-left-side-start {
    opacity: 100;
}

.new_application-container-right-side-start {
    opacity: 100;
}