
@media only screen and (max-width: 720px) {
    .how-does-it-work {
        margin-top: -40px !important;
    }
    .how-does-it-work-title {
        font-size: 36px;
        margin-top: 30px !important;
    }
    .how-does-it-work-section {
        margin-bottom: 30px !important;
    }
    .how-does-it-work-img-1,
    .how-does-it-work-img3 {
        width: 100% !important;
        margin-bottom: 50px;
        transform: translate(0);
    }
    .how-does-it-work-section1-title {
        font-size: 22px;
        width: 98vw !important;
        transform: translateX(-10%);
    }
    .how-does-it-work-section1-description,
    .how-does-it-work-section2-description {
        width: 90% !important;
        font-size: 13px;
        transform: translateX(-10%);
    }
    .how-does-it-work-section1-right-side{
        max-width: 90vw !important;
        transform: translate(-5%,0%);
    }
    .how-does-it-work-section2 {
        margin-top: 50px !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        margin-bottom: 50px;
    }
    .how-does-it-work-img-2 {
        width: 100% !important;
        margin-bottom: 30px;
        transform: translateX(0);
    }
    .how-does-it-work-section2-left-side {
        display: flex;
        align-items: center;
        transform: translate(0,0);
    }
}
