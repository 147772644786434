
@media only screen and (max-width: 725px) {
    .profile-settings-info {
        display: block;
    }
    .profile-settings-pic {
        margin-left: auto;
        margin-right: auto;
    }
    .profile-settings-inputs {
        width: 100%;
        margin-top: 35px;
    }
    .profile-settings-surname-input {
        margin-top: 30px;
    }
    .profile-settings-level-wrapper {
        display: block;
        height: fit-content;
        height: 180px;

    }
    .profile-settings-intrests-wrapper {
        display: block;
        height: 900px;
    }
    .profile-settings-goals-wrapper {
        height: 600px;
    }
    .profile-settings-save-changes {
        width: 100%;
    }
    .profile-level-button {
        width: fit-content;
        float: left;
        display: block;
    }
    .profile-intrests-button {
        width: fit-content;
        float: left;
        display: block;
    }
    .profile-settings-image-cropper-container {
        width: 100%;
    }
    .profile-settings-image-cropper-btns-container {
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .profile-settings-image-cropper-btn {
        width: 100%;
        margin-top: 10px;
    }
    .profile-settings-image-cropper-btn:nth-child(1) {
        margin-right: 0;
    }
}

/********************* Level Buttons **************************/

@media only screen and (max-width: 375px) {
    .profile-settings-level-wrapper {
        height: 180px;
    }
}
@media only screen and (max-width: 720px) and (min-width: 376px) {
    .profile-settings-level-wrapper {
        height: 120px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 720px) {
    .profile-settings-level-wrapper {
        height: 120px;
        display: block;
    }
}

/************************ Interests Buttons *****************************/

@media only screen and (max-width: 280px) {
    .profile-settings-intrests-wrapper {
        height: 1200px;
    }
}

@media only screen and (max-width: 374px) and (min-width: 321px) {
    .profile-settings-intrests-wrapper {
        height: 830px;
    }
}
@media only screen and (max-width: 295px) and (min-width: 280px) {
    .profile-settings-intrests-wrapper {
        height: 1100px;
    }
}
@media only screen and (max-width: 343px) and (min-width: 290px) {
    .profile-settings-intrests-wrapper {
        height: 980px;
    }
}
@media only screen and (max-width: 389px) and (min-width: 375px) {
    .profile-settings-intrests-wrapper {
        height: 810px;
    }
}

@media only screen and (max-width: 428px) and (min-width: 390px) {
    .profile-settings-intrests-wrapper {
        height: 780px;
    }
}

@media only screen and (max-width: 419px) and (min-width: 419px) {
    .profile-settings-intrests-wrapper {
        height: 670px;
    }
}
@media only screen and (max-width: 430px) and (min-width: 419px) {
    .profile-settings-intrests-wrapper {
        height: 630px;
    }
}
@media only screen and (max-width: 443px) and (min-width: 420px) {
    .profile-settings-intrests-wrapper {
        height: 620px;
    }
}
@media only screen and (max-width: 480px) and (min-width: 461px) {
    .profile-settings-intrests-wrapper {
        height: 520px;
    }
}
@media only screen and (max-width: 460px) and (min-width: 444px) {
    .profile-settings-intrests-wrapper {
        height: 580px;
    }
}
@media only screen and (max-width: 496px) and (min-width: 482px) {
    .profile-settings-intrests-wrapper {
        height: 520px;
    }
}
@media only screen and (max-width: 720px) and (min-width: 497px) {
    .profile-settings-intrests-wrapper {
        height: 460px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 720px) {
    .profile-settings-intrests-wrapper {
        height: 500px;
    }
}


/********************** Goals Buttons **********************************/

@media only screen and (max-width: 347px) and (min-width: 391px) {
    .profile-settings-goals-wrapper {
        height: 620px;
    }
}
@media only screen and (max-width: 361px) and (min-width: 350px) {
    .profile-settings-goals-wrapper {
        height: 560px;
    }
}
@media only screen and (max-width: 480px) and (min-width: 362px) {
    .profile-settings-goals-wrapper {
        height: 420px;
    }
}
@media only screen and (max-width: 519px) and (min-width: 481px) {
    .profile-settings-goals-wrapper {
        height: 320px;
    }
}
@media only screen and (max-width: 497px) and (min-width: 480px) {
    .profile-settings-goals-wrapper {
        height: 380px;
    }
}
@media only screen and (max-width: 540px) and (min-width: 520px) {
    .profile-settings-goals-wrapper {
        height: 320px;
    }
} 
@media only screen and (max-width: 720px) and (min-width: 541px) {
    .profile-settings-goals-wrapper {
        height: 260px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 721px) {
    .profile-settings-goals-wrapper {
        height: 380px;
    }
}



