
@media only screen and (max-width: 720px) {
    .instructor-support-banner {
        height: 30vh;
        min-height: 160px;
        max-height: 250px;
    }
    .support-links {
        padding: 24px 15px;
    }
    .support-content {
        margin: 0;
        width: 100vw !important;
        padding: 0 14px !important;
    }
    .support-navigation-container {
        display: block;
        margin-bottom: 15px;
    }
    .support-navigation {
        height: 0%;
        opacity: 0;
    }
    .support-navigation *{
        display: none;
    }
    .banner-title {
        transform: translateY(45%);
        font-size: 50px;
    }
    .support-tablet-navigation {
        display: none;
    }
    .support-tablet-arrow {
        display: none;
    }
    .support-down-arrow {
        display: block;
        width: 100%;
        margin: 15px 0 !important;
    }
    .support-down-arrow-icon {
        width: 100%;
    }
    .support-body {
        padding: 30px 16px;
    }
    .support-footer {
        margin-top: 75px;
    }
}
