
.choose-package {
    min-height: 100vh;
    background-color: #fafafa;
}
.choose-package-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
}
.choose-package-container {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 0;
    padding-right: 20px;
    margin: 0;
}
.choose-packages-footer-container {
    margin-top: 90px;
}

.current-package {
    border-color: #45494D;
}
.current-package:hover {
    border-color: #45494D;
    cursor: initial;
} 
.current-package .discount-area {
    background-color: #45494D;
}
.current-package:hover .discount-area {
    background-color: #45494D;
}
