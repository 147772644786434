
.current-package {
    border-color: #45494D;
}
.current-package:hover {
    border-color: #45494D;
    cursor: initial;
} 
.current-package .discount-area {
    background-color: #45494D;
}
.current-package:hover .discount-area {
    background-color: #45494D;
}
