
.chatroom-options {
    width: 100%;
    height: 280px;
    background:  linear-gradient(360deg, rgba(38, 38, 38, 0.6) 0%, rgba(38, 38, 38, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    display: none;
}
.student-video-chat-container:hover .chatroom-options {
    transition: bottom 0.5s ease-in;
    display: flex;
}
.chatroom-options-container {
    height: 48px;
    width: 175px;
    display: flex;
    margin: 0 auto 30px;
    align-items: center;
    justify-content: space-between;
}
.chatroom-options-container>img {
    cursor: pointer;
}

@media only screen and (max-width: 960px) {
    .chatroom-options-container {
        transform: translateX(80px);
    }
}
