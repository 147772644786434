
@media only screen and (max-width: 720px) {
    .make-reservation-instructor-list-container {
        height: 2750px;
    }
    .make-reservation-title-container {
        display: block;
        height: 75px;
    }
    .make-reservation-subtitle {
        margin-left: 0;
    }
}
