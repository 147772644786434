@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-profile-information-container {
    width: 100%;
    height: fit-content;
}
.instructor-profile-information-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-profile-information-modif-container {
    display: flex;
    align-items: flex-start;
    margin-top: 19px;
}
.instructor-profile-information-upload-image-container {
    height: 150px;
    width: 141px;
}
.instructor-profile-information-image-uploaded-container {
    height: 133.5px;
    width: 100%;
    border-radius: 24px;
}
.instructor-profile-information-image-uploaded-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.instructor-profile-information-image-uploaded-wrapper>img {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.instructor-profile-information-image-uploaded-filter {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 47.02%, rgba(0, 0, 0, 0.6) 100%);
    border-radius: 24px;
}
.instructor-profile-information-upload-icon-container {
    height: 41px;
    width: 41px;
    display: block;
    margin: 0 auto;
    transform: translateY(-50%);
    z-index: 5;
    position: relative;
}
.instructor-profile-information-upload-icon-container>img {
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.instructor-profile-information-settings-container {
    height: fit-content;
    width: 80%;
    margin-left: 15px;
    display: block;
}
.instructor-profile-information-setting-input-container {
    width: 100%;
    min-width: 475px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}
.instructor-profile-information-setting-input-label {
    text-align: left;
    color: #52575C;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    transform: translateY(20%);
}
.instructor-profile-information-setting-wrapper {
    width: 70%;
    min-width: 320px;
}
.instructor-profile-information-setting-wrapper.multiple-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.instructor-profile-information-setting-input {
    width: calc(100% - 60px);
    min-width: 255px;
}
.instructor-profile-information-sep {
    width: 100%;
    background-color: #CACCCF;
    margin-top: 30px;
}
