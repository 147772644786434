.new_certified-trainers {
    margin-top: 110px;
}

.new_certified-trainers-container {
    background: rgba(210, 235, 252, 0.55);
    border-radius: 24px;
    padding: 9 20 20 9;
}

.new_certified-trainers-item-title {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    align-items: flex-end;
    color: #45494D;
}

.new_certified-trainers-item-description {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: flex-end;
    color: #45494D;
    margin-top: 16px;
    margin-bottom: 16px;
}

.new_certified-trainers-item-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    background: #D64502;
    border-color: transparent !important;
    border-radius: 8px;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.new_certified-trainers-item-image{
    max-height: 100%;
    max-width: 100%;
}