@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.experts-instructors {
    margin-top: 70px;
}

.experts-instructors-title {
    text-align: center;
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;
}

.new_experts-instructors-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    align-items: center;
    letter-spacing: 0.025em;
    color: rgba(35, 35, 35, 0.9);
}

.new_experts-instructors-description {
    width: 100%;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #45494D;
    margin-top: 18px;
    margin-bottom: 32px;
}

.experts-instructors-description {
    width: 100%;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    line-height: 25px;
    font-size: 18px;
    color: #929598;
    margin-top: 18px;
}

.experts-instructors-img-container {
    /* transform: translateX(2%); */
}

.experts-instructors-img {
    width: 100% !important;
    /* transform: translateX(20%); */
}

/* .experts-instructors {
    animation-name: rightSide;
    animation-duration: 0.7s;
}

.experts-instructors-start {
    opacity: 0;
} */

@media only screen and (min-width: 1420px) {
    .experts-instructors-img {
        /* transform: translateX(34%); */
    }
}