
@media only screen and (max-width: 720px) {
    .why-speaking-line {
        height: 550px;
    }
    .why-speakingline-title {
        font-size: 36px;
    }
    .why-speakingline-cards-container {
        overflow-x: scroll;
    }
    .why-speakingline-cards {
        width: 1140px !important;
        display: block;
        padding-bottom: 30px;
    }
    .why-speakingline-item-wrapper {
        width: 250px;
        height: 360px;
        float: left;
        margin-left: 20px;
    }
    .why-speakingline-card-description {
        width: 80%;
    }
}
