
@media only screen and (max-width: 720px) {
    .payment-total-bill-container {
        margin-top: 20px;
    }
    .go-back-payment {
        margin-left: auto;
        margin-right: auto;
    }
}
