@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.current-subscription-package {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 15px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
}
.current-subscription-details {
    flex: 2;
}
.current-subscription-title {
    text-align: left;
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 16px;
}
.current-subscription-subtitle {
    text-align: left;
    color: #45494D;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}
.current-subscription-price {
    flex: 1;
}
.current-subscription-price-title {
    text-align: right;
}
