
@media only screen and (max-width: 992px) {
    .footer-top-link {
        font-size: 13px;
    }
    .footer-bottom-centered-links>p {
        font-size: 13px;
    }
    .footer-bottom>p {
        font-size: 13px;
    }
    .footer-bottom-social-media {
        margin-left: 15px;
    }
    footer {
        height: 140px;
    }
}
