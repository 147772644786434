@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.banner {
    width: 100vw;
    margin-top: 37px;
}

.banner-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner-img {
    width: 100%;
}

.home-banner-title {
    width: 75%;
    text-align: left;
    color: var(--black);
    font-size: 37px;
    line-height: 44px;
    font-family: 'Overpass', sans-serif;
    font-weight: 700;
}

.new_home-banner-title {
    text-align: left;
    color: var(--white);
    /* font-size: 37px; */
    /* line-height: 44px; */
    /* font-family: 'Overpass', sans-serif; */
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 50px;
    line-height: 120%;
}

.new_banner-description {
    width: 85%;
    text-align: left;
    color: var(--white);
    font-size: 18px;
    line-height: 25px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    margin-top: 10px !important;
}

.banner-description {
    width: 85%;
    text-align: left;
    color: var(--darkGray);
    font-size: 18px;
    line-height: 25px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    margin-top: 10px !important;
}

.banner-btn {
    background-color: #5389F8 !important;
    border-color: #5389F8 !important;
    border-radius: 10px !important;
    padding: 10px 25px;
    margin-top: 30px;
}

.banner-btn:hover {
    background-color: #233c6d !important;
    border-color: #233c6d !important;
}

.new_banner-btn {
    background-color: #F86F53 !important;
    border-color: #F86F53 !important;
    border-radius: 8px !important;
    padding: 10px 25px;
    margin-top: 30px;
    margin-bottom: 90px;
    width: 206px;
    height: 44px;
}

.new_banner-btn:hover {
    background-color: #233c6d !important;
    border-color: #233c6d !important;
}

.banner-right-side {
    animation-name: rightSide;
    animation-duration: 0.7s;
}

.banner-left-side {
    animation-name: leftSide;
    animation-duration: 0.7s;
}