@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.update-payment-informations {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
    background-color: #F2F2F2;
    border-radius: 24px;
}
.update-payment-informations-title {
    text-align: left;
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 30px;
}
.update-payment-informations-conditions {
    margin-top: 30px;
    width: 100%;
}
