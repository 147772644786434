.chatroom-popup-container {
    height: 500px;
    padding: 4px;
    width: 400px;
    /* border: 1px solid #F00; */
    border-radius: 20px;
    float: left;
    overflow-y: auto;
}

.chatroom-no-records-container {
    width: 100%;
    height: 100%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.chatroom-no-records-text {
    margin: 0;
    padding: 0;
    margin-top: 8px;
    text-align: center;
    color: #929598;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
    max-width: 235px;
}

.chat-list img
{
    background: none !important;
}
.popover
{
    max-width: 100% !important;
    width: 400px;
}