
@media only screen and (min-width: 721px) and (max-width: 960px) {
    .subscription-detail-plan {
        margin-bottom: 25px;
    }
    .subscription-details-my-payments-total-container {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .subscription-plan-btn {
        width: 100% !important;
    }
}
