@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@media only screen and (max-width: 720px) {
    .chatroom-wrapper {
        width: 92%;
        border-radius: 0;
        background-color: rgba(82, 87, 92, 0.95);
        display: flex;
        flex-direction: column;
    }
    .chatroom-div-wrapper {
        width: 100%;
        border-radius: 0;
    }
    .chatroom-mobile-header {
        width: 100%;
        height: 55px;
        background-color: #434546;
        display: flex;
        box-sizing: border-box;
        padding: 18px 16px;
    }
    .chatroom-mobile-header-name {
        text-align: left;
        color: #fff;
        font-size: 19px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        font-family: 'Nunito', sans-serif;
    }
    .chatroom-mobile-header-nationality {
        transform: translate(14px, 3px);
    }
}
