
@media only screen and (max-width: 960px) {
    .navbar-mobile {
        width: 100% !important;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .main-nav-bar-burger-menu {
        transform: translateX(-20px);
    }
    .main-nav-bar-burger-menu-light {
        transform: translate(0, 2px);
    }
}
