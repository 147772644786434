
.chatroom-icon-container {
    height: 48px;
    width: 48px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    cursor: pointer;
}
.chatroom-div-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.chatroom-container {
    width: 350px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.chatroom-close-icon {
    height: 30px;
    width: 30px;
    margin-top: 12px;
    margin-right: 5px;
    cursor: pointer;
}
.chatroom-wrapper {
    height: 100%;
    width: 290px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(69, 73, 77, 0.4);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.chatroom-input-container {
    width: 100%;
    padding: 0 16px 15px;
}
.chatroom-messages-container {
    width: 100%;
    height: 88%;
    padding: 0 16px;
    overflow: hidden;
}
.chatroom-mobile-header {
    display: none;
}
