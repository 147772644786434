@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-profile-experience-container {
    width: 100%;
    margin-top: 30px;
}
.instructor-profile-experience-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-profile-experience-desc-container {
    width: 100%;
    height: auto;
    min-height: 120px;
    margin-top: 15px;
    color: #929598 !important;
    /* background-color: #fff; */
    /* border: 1px solid #CACCCF; */
    border-radius: 4px;
    box-sizing: border-box;
    /* padding: 15px 12px; */
    
}

.instructor-profile-experience-desc-container input :active { 
    border:none;
    outline: none;
    box-shadow: none;
}
.instructor-profile-experience-desc {
    height: 100%;
    width: 100%;
    border: none;
    text-align: left;
    color: #929598;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    
}
.instructor-profile-experience-desc  input :active{
    border:none;
    outline: none;
    box-shadow: none;
    
}
