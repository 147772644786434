
.content {
    display: flex;
    align-items: center;
}

.icon
{
    width: 300px;
    margin-right: 50px;
}

.content-rate-group
{
    align-items: center;
    width: auto;
    padding-right: 20px;
}

.content-rate-group h3
{
    color:#929598;
    margin-bottom: 3px;
    font-size: 15px;
}