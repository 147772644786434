
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .application-container {
        width: 100% !important;
    }
    .application-title {
        font-size: 36px;
    }
    .application-description {
        font-size: 22px;
    }
    .download-buttons {
        width: 400px !important;
    }
    .google-play-icon {
        margin-right: 20px;
    }
}
