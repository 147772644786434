@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.signup-form {
    display: flex;
    justify-content: center;
}
.signup-form-container {
    width: 90vw;
    max-width: 700px;
    padding: 40px 80px;
    border-radius: 24px;
    border: 0
}
.signup-form-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--black)
}
.signup-form-subtitle {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.signup-form-btns {
    display: flex;
    justify-content: space-between;
}
.signup-form-fb-btn {
    padding: 14px 25px;
    background-color: #3D5288;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border-radius: 50px;
    border: 0;
}
.signup-form-fb-btn:hover {
    background-color: #152346;
}
.signup-form-google-btn {
    padding: 14px 25px;
    background-color: #fafafa;
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid var(--black);
    border-radius: 50px;
}
.signup-form-google-btn:hover {
    background-color: #EAEAEA;
    border: 1px solid var(--black);
    color: var(--black);
}
.signup-sep {
    width: 100%;
    height: 1px;
    background-color: #9a9a9a;
}
.signup-sep-title {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 10px;
    background-color: #fff !important;
    margin-top: -14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.signup-label {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #41566D;
}
.checkbox-label {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #677294;
    max-width: 400px;
}
.signup-btn {
    width: 100%;
    background-color: #F86F53;
    border-radius: 40px;
    border: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
}
.signup-btn:hover {
    background-color: #E55F44;
}

.not-a-member {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13.5px;
    color: var(--black);
    margin-left: auto;
    margin-right: auto;
}
.form-links {
    display: flex;
    justify-content: space-between;
}
/* .password-icon-container {
    background-color: transparent;
    border: 0;
    cursor: pointer;
} */
.password-input {
    border: 0;
}
.password-input-container {
    border: 1px solid #ced4da;
    border-radius: 5px;
}
.password-input:focus {
    border-color: transparent !important;
    box-shadow: inset 0 0 0 0 transparent !important;
    border-bottom: 1px solid transparent !important;
}
.password-input-focus {
    border-bottom: 1px solid #F86F53 !important;
    border-top: 1px solid #F86F53 !important;
}
.signup-input-focus:focus {
    border-color: #F86F53 !important;
    box-shadow: inset 0 0 0 1px #F86F53 !important;
    border-bottom: 1px solid #F86F53 !important;
}
.password-input-container-focus {
    border-color: #F86F53 !important;
    box-shadow: inset 0 0 0 1px #F86F53 !important;
    border-bottom: 1px solid #F86F53 !important;
}

.signup-input {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
