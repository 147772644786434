
@media only screen and (max-width: 720px) {
    .pause-subscription {
        height: 100% !important;
        overflow-x: scroll !important;
        align-items: flex-start;
    }
    .pause-subscription-modal {
        width: 95vw;
        min-width: 95vw;
    }
    .pause-subs-options {
        height: 250px;
        margin-bottom: 30px;
        margin-top: 25px;
    }
    .pause-subscription-modal-package-two {
        height: 180px !important;
        margin-top: 15px;
    }
    .pause-automatic-renewel-btn {
        font-size: 13px;
    }
}
