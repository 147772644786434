.toastcontainer {
	font-size: 14px;
}

.toast {
    text-align: center;
    margin-left: auto;
    width: 400px;
    flex: 1;
    height: fit-content;
    margin-top: 30px;
    margin-right: 30px;
}

.toast:hover {
	opacity: .8;
	cursor: pointer;
}

.toastcontainer:hover {
	opacity: .9;
	cursor: pointer;
}

.toasttitle {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 10px;
	width: 300px;
	height: 18px;
}
.toastname {
	/* font-weight: 700; */
	/* font-size: 16px; */
	text-align: left;
}
.toastmessage {
	/* font-weight: 700; */
	/* font-size: 16px; */
	text-align: left;
	word-wrap: break-word;
    white-space: break-spaces;
}
.toastdescription {
	margin: 0;
	text-align: left;
	margin-left: -1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 5px;
}

.toastimage {
	float: left;
	margin-right: 15px;
}

.toastimage img {
	width: 25px;
	height: 25px;
}

.toastsupersnack {
	height: 50px;
	width: 275px;
	color: #fff;
 	padding-top:10px;
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 1rem !important;
}

.toastcontainer .toastclosebutton {
	position: relative;
	right: -.3em;
	top: -.3em;
	float: right;
	width: 4%;
	cursor: pointer;
}

.toastcontainer .toastclosebutton:hover {
	transform: scale(.8);
}

.topright {
	top: 17px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: animatefromright;
	
}

.bottomright {
	bottom: 17px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: animatefromright;
	

}

.topleft {
	top: 17px;
	left: 12px;
	transition: transform .6s ease-in-out;
	animation: animatefromleft;
	

}

.bottomleft {
	bottom: 17px;
	left: 12px;
	transition: transform .6s ease-in-out;
	animation: animatefromleft;
	

}

.bottomup {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	bottom: 0;
	transition: transform .6s ease-in-out;
	animation: animatefrombottom;
	

}

.topdown {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 0;
	transition: transform .6s ease-in-out;
	animation: animatefromtop;
	

}

@keyframes animatefromright {
	0% {
		transform: translateX(100%);
	}

	25% {
		transform: translateX(0%);
	}

	50% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes animatefromleft {
	0% {
		transform: translateX(-100%);
	}

	25% {
		transform: translateX(0%);
	}

	50% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes animatefrombottom {
	0% {
		transform: translateY(100%);
	}

	25% {
		transform: translateY(-30%);
	}

	50% {
		transform: translateY(-30%);
	}

	100% {
		transform: translateY(100%);
	}
}

@keyframes animatefromtop {
	0% {
		transform: translateY(-100%);
	}

	25% {
		transform: translateY(30%);
	}

	50% {
		transform: translateY(30%);
	}

	100% {
		transform: translateY(-100%);
	}
}