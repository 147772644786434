@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

.packages-titles {
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin: 0;
    padding: 0;
}
.packages-subtitle {
    color: var(--darkGray);
    font-family: 'Nunito', sans-serif;
    font-size: 16px;    
    margin: 0;
    padding: 0;
}
.option-title {
    font-family: 'Nunito', sans-serif;
    color: var(--black);
    font-weight: 700;
    font-size: 22px;
    margin-top: 30px;
}
.option-odds {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
}
.option-odd {
    width: 24%;
    max-width: 250px;
    background-color: transparent;
    padding: 12px 0;
    border: 1px solid #CACCCF;
    color: var(--darkGray);
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    margin-right: 10px;
}
.option-odd:focus,
.option-odd:active {
    outline: none;
    box-shadow: none;
}
.option-odd:hover {
    background-color: transparent;
    border: 1px solid #F86F53;
    color: #F86F53;
}
.option-odd-active {
    background-color: #F86F53 !important;
    border-color: #F86F53;
    color: #fff !important;
}
.option-odd-active:hover {
    border: 0;
    background-color: #F86F53;
}
.subscription-packages {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.subscription-package {
    width: 32%;
    max-width: 350px;
    padding: 20px 0 0;
    border: 1px solid #CACCCF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.subscription-package:hover {
    border-color: #F86F53;
}
.subscription-package:hover .discount-area {
    background-color: #F86F53;
}
.subscription-package.active {
    border-color: #F86F53;
}
.subscription-package.active .discount-area {
    background-color: #F86F53;
}
.package-title {
    text-align: center;
    color: var(--black);
    font-family: 'Nunito', sans-serif;
    font-size: 36px;
    font-weight: 700;
}
.prev-price {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--darkGray);
}
.current-price {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #F86F53;
}
.auto-renew {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--darkGray);
}
.discount-area {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--darkGray);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.discount {
    transform: translateY(45%);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.go-to-checkout {
    width: 100%;
    background-color: #CACCCF;
    padding-top: 14px;
    padding-bottom: 14px;
    border: 0;
    border-radius: 40px;
}
.go-to-checkout:hover {
    background-color: #426FCD;
}
.checkout-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.packages-link {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--darkGray);
}
.subscription-badge {
    background-color: #FDD9D1;
    color: #F86F53;
    padding: 7px 15px;
    margin-left: 5px;
}


.current-subscription-package {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 15px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
}
.current-subscription-details {
    flex: 2;
}
.current-subscription-title {
    text-align: left;
    color: #F86F53;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 16px;
}
.current-subscription-subtitle {
    text-align: left;
    color: #45494D;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}
.current-subscription-price {
    flex: 1;
}
.current-subscription-price-title {
    text-align: right;
}

