@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-support-banner {
    height: 45vh;
    min-height: 225px;
    max-height: 500px;
    max-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.instructor-support-banner-container {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}
.support-content {
    margin-top: 30px;
}
.banner-title {
    text-align: left;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 140px;
    transform: translateY(20%);
}
.support-navigation {
    width: 100%;
    min-width: 210px;
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid #F86F53;
    border-bottom: 8px solid #F86F53;
    opacity: 1;
}
.support-links {
    background-color: #F86F53;
    box-sizing: border-box;
    padding: 30px 15px;
    border-radius: 15px;
    display: block;
}
.support-link-container {
    width: 100%;
    padding: 6px 8px;
    margin-bottom: 11px;
    border-radius: 6px;
}
.support-link-container.active {
    background-color: #DF4323;
}
.support-link {
    text-align: left;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.support-link:hover {
    text-decoration: none;
    color: #fff;
}
.support-contact {
    padding: 25px 18px;
}
.support-contact-container {
    margin-bottom: 20px !important;
}
.support-contact-title {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: var(--darkGray);
    margin-top: 3px !important;
}
.support-contact-info {
    text-align: left;
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: 0 !important;
}
.support-body {
    border-radius: 20px !important;
    border: 0 !important;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
}
.support-footer {
    margin-top: 100px !important;
}
.support-down-arrow {
    display: none;
}
.support-tablet-arrow {
    display: none;
}

.support-open-nav-anim {
    transition: height 0.1s ease;
    animation-name: openNavMobile;
    animation-duration: 0.4s;
    height: 500px !important;
    opacity: 1 !important;
}
.support-open-nav-anim-content * {
    display: block !important;
}

@keyframes openNavMobile {
    0% {
        transform: translateY(0%);
        transform-origin: top;
        opacity: 0;
    }
    50% {
        transform: translateY(100%);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

