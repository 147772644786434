
@media only screen and (max-width: 720px) {
    .packages-titles {
        font-size: 22px;
    }
    .packages-subtitle {
        font-size: 16px;
    }
    .option-title {
        font-size: 14px;
        margin-top: 30px;
    }
    .option-odds {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .option-odd {
        font-size: 16px;
        width: 95%;
        display: block;
        margin-right: 3%;
        margin-bottom: 3%;
    }
    .option-odd-active {
        float: left;
        display: block;
    }
    .package-section {
        display: block;
    }
    .subscription-option-title {
        display: flex;
        flex-direction: column;
        width: 65vw;
        margin: 30px auto 0;
        text-align: center;
        font-size: 22px;
    }
    .subscription-packages {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .subscription-package {
        width: 90%;
        margin-top: 30px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(3%);
    }
    .subscription-option-title>span {
        width: fit-content;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .subscription-badge {
        font-size: 15px;
        transform: translateX(-7%);
        margin-top: 5px;
        margin-left: 0;
    }
}
