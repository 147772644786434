@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-reservations-top-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.instructor-reservations-top-bar-period-container {
    width: 450px;
    height: 50px;
    display: flex;
    align-items: center;
}
.instructor-reservations-period {
    width: 150px;
    height: 50px;
    background-color: #5389F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.instructor-reservations-period:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.instructor-reservations-period:nth-child(3) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.instructor-reservations-period>p{
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.instructor-reservations-period.selected {
    background-color: #031E55;
}
.instructor-reservations-top-bar-time-range-input-container {
    width: 445px;
    height: 40px;
}
.reservations-top-bar-search-input {
    min-width: 290px;
    width: 35%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
