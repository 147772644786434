.modal-header {
    background: #E9ECEF;
}

.modal-header .modal-title {
    font-weight: 700;
    color: #777777;
    font-family: 'Nunito';
}

.tutor-infor {
    display: flex;
    margin-bottom: 15px;
}

.tutor-img {
    width: 15%;
}

.tutor-detail {
    width: 85%;
}

.name-details {
    font-family: 'Nunito', sans-serif;
    justify-content: space-between;
}

.name-details .time-para {
    color: #929598;
    font-size: 12px;
    margin: 0 5px 0 0;
}

.name-details .fLqabo {
    margin-top: 0;
    border-radius: 4px;
}

.time-rate {
    display: flex;
    align-items: center;
}

.name-details .sc-hTZhsR {
    align-items: center;
}

.name-details .sc-hTZhsR img {
    transform: none;
}

.tutor-avail {
    margin: 5px 0 10px;
}

.tutor-avail button {
    border: 1px solid #929598;
    font-family: 'Nunito';
    color: #777;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 600;
}

.tutor-avail button:nth-child(1) {
    width: 35%;
}

.tutor-avail button:nth-child(2) {
    width: 65%;
}

.res-actions button {
    padding: 5px 25px;
    background: #F2F2F2;
    border-radius: 8px;
    border: none;
    color: #45494D;
    font-family: Nunito;
    margin-right: 10px;
    font-weight: 600;
    flex-grow: 1;
}

.res-actions button:hover {
    background: #F86F53;
    color: #fff;
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    margin: 16px 0;
}

.tutor-detail-wrapper .content-inner h3 {
    color: #777;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 2px 0;
}

.tutor-detail-wrapper .content-inner {
    margin-bottom: 30px;
}

.tutor-detail-wrapper .content-inner p {
    color: #52575C;
    font-family: 'Nunito';
    font-size: 16px;
}

.tutor-img img {
    object-fit: cover;
}

.tag.danger {
    color: #F86F53;
    background: #FFEEEB;
    font-weight: 600;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 12px;
    padding: 2px 6px;
    font-family: 'Nunito';
}

span.tag.info {
    color: #5389F8;
    background: #E5EEFF;
    font-weight: 600;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 12px;
    padding: 2px 6px;
    font-family: 'Nunito';
}

.reservation-wrapper {
    background: #F2F2F2;
    text-align: center;
    margin: 30px 0;
    border-radius: 8px;
    font-family: 'Nunito';
    padding: 20px;
}

.reservation-wrapper p {
    margin: 0 0 5px 0;
    color: #52575C;
    font-size: 16px;
}

.reservation-wrapper button {
    background: #5389F8;
    border-radius: 8px;
    border: none;
    width: 100%;
    max-width: 250px;
    margin-top: 15px;
}

.calendear-container {
    font-family: 'Nunito';
    border-top: 1px solid #CACCCF;
    margin-top: 10px;
    float: left;
    width: 100%;
    padding-top: 20px;
}

.calendear-wrapper {
    font-family: 'Nunito';
    /* border-top: 1px solid #CACCCF;
    margin-top: 10px; */
    float: left;
    width: 100%;
    /* padding-top: 20px; */
}

.calendear-wrapper .input-group-text {
    background: #929598;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    border: none;
    padding: 10px 8px;
}

.calendear-wrapper .input-group-text.input-right {
    border-radius: 0px 4px 4px 0px !important;
}

.calendear-wrapper .form-control {
    text-align: center;
    height: 46px;
    border: none;
    font-size: 16px;
    background: #F2F2F2;
    font-weight: 700;
}

.calendear-wrapper h6 {
    color: #777;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 2px 0;
    margin-bottom: 10px;
}

.date-box {
    padding: 8px;
    width: 90px;
    border-radius: 4px;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.date-box h3 {
    font-size: 22px;
    font-weight: 600;
}

.date-box p {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.date-box p.day-wrapper {
    font-size: 14px;
}

.time-range .btn {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border: 1px solid #929598;
    border-radius: 4px;
    color: #929598;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    height: 40px;
    align-items: center;
}

.time-range .btn :focus {
    outline: none;
    box-shadow: none;
}
.time-range .btn :hover {
    transition: background-color 0.3 ease-in-out;
    background-color: rebeccapurple !important;
}

.time-range {
    margin: 0 -10px;
}


.time-slot .btn {
    border: 1px solid #929598;
    border-radius: 4px;
    color: #929598;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
}

.modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 15px 20px rgba(141, 141, 141, 0.25);
}

.confirmationModalBody .cal-icon {
    text-align: center;
}

.confirmationModalBody {
    font-family: 'Nunito';
    text-align: center;
}

.confirmationModalBody>h5 {
    color: #45494D;
    font-size: 28px;
    font-weight: 700;
}

.confirmationModalBody .content-inner p.large-para {
    font-size: 16px;
    color: #45494D;
    padding: 5px 50px;
}

.confirmationModalBody .content-inner p {
    padding: 5px 10px;
    font-size: 14px;
}

.confirmationModalBody .content-inner p.large-para span {
    color: #F86F53;
    font-weight: 700;
}

.customModalFooter {
    justify-content: center;
    border: none;
}

.customModalFooter .btn-secondary {
    background: transparent !important;
    border: none;
    color: #45494D !important;
    font-size: 14px;
    font-weight: 600;
}

.customModalFooter .btn-primary {
    background: #F86F53 !important;
    border-color: #F86F53 !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}