@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-profile-video-container {
    width: 100%;
    margin-top: 30px;
}
.instructor-profile-video-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
