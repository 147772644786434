
@media only screen and (max-width: 960px) and (min-width: 720px) {
    .support-instructor {
        font-size: 24px;
    }
    .instructors-support-link,
    .instructors-support-text p {
        font-size: 14px;
    }
}
