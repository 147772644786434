@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.support-questions-title {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: var(--black);
    font-size: 32px;
}
.category-title-1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: var(--black);
    margin-top: 30px;
}
.intsructor-questions-accordion-container {
    margin-top: 22px;
}
.instructor-questions-accordion {
    margin-bottom: 15px;
}
.support-question-container {
    background-color: #E0E0E0 !important;
}
.support-accordion-header {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    background-color: #E0E0E0;
}
.support-question-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    margin: 0;
    padding: 0;
    padding: 18px !important;
    text-align: left;
}
.support-question-title:hover {
    text-decoration: none;
    color: #2F80ED;
}
.support-question-title:focus,
.support-question-title:active {
    box-shadow: none;
    text-decoration: none;
    color: #2F80ED;
}
.support-question-title.active {
    color: #2F80ED;
}
.support-question-parag {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--black);
    margin: 0 !important;
    padding: 18px !important;
}
.question-wrapper {
    border: 0 !important;
}