
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .payment-go-back {
        width: 125px;
        transform: translateX(-20px);
        margin: 60px auto 0;
    }
    .payment-footer-container {
        margin-top: 70px;
    }
}
