@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.privacy-policy-title {
    text-align: left;
    color: #52575C;
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
    font-family: 'Nunito', sans-serif;
}
.privacy-policy-subtitle {
    text-align: left;
    color: #52575C;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    transform: translateY(-7px);
    margin-bottom: 10px;
}
.privacy-policy-paragraph {
    text-align: left;
    color: #52575C;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    margin-top: 20px;
}
.privacy-policy-body {
    padding: 30px 24px;
}
.privacy-page-header {
    background-color: #F86F53;
}
.privacy-page-header-title-container {
    padding: 30px 45px;
}
.privacy-page-header-title-container h4 {
    text-align: left;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    line-height: 44px;
}
