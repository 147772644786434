@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.upgrade-subscription {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
.upgrade-subscription-modal {
    border: 0 !important;
    width: 600px;
    outline: none;
}
.upgrade-subscription-modal-header {
    background-color: #f2f2f2;
    padding: 18px 24px;
    border: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.upgrade-subscription-modal-title {
    text-align: left;
    color: #45494D;
    font-size: 19px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    transform: translateY(5px);
}
.subscription-modal-package {
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    padding: 15px 17px;
    background-color: #f2f2f2;
    border-radius: 10px;
}
.subscription-modal-package-title {
    text-align: center;
    color: #F86F53;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.subscription-modal-package-title.date {
    margin-top: 5px !important;
}
.subscription-modal-content {
    margin-top: 15px;
}
.subscription-modal-content.date {
    margin-top: 5px;
}
.subscription-modal-content-title {
    text-align: center;
    color: #45494D;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.subscription-modal-content-title.date {
    margin-top: 5px;
}
.automatic-renewel-cancelation {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 16px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-top: 15px;
}
.automatic-renewel-title {
    text-align: left;
    color: #45494D;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.automatic-renewel-parag {
    text-align: left;
    color: #45494D;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
    margin-top: 10px;
}
.automatic-renewel-cancelled {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 13px 16px;
    background-color: #929598;
    border-radius: 10px;
    margin-top: 15px;
}
.automatic-renewel-cancelled>h4 {
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}

