
@media only screen and (max-width: 720px) {
    .banner {
        margin-top: 10px !important;
    }
    .home-banner-title {
        width: 90%;
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        margin-top: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-description {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
    }
    .banner-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }
}
