@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new-how-does-it-work {
    margin-top: 80px;
    margin-bottom: 80px;
}

.new-how-does-it-work-section1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.new-how-does-it-work-title {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    color: #F86F53;
}

.new-how-does-it-work-section1-right-side {
    display: flex;
    align-items: center;
    white-space: "pre-line";
    /* transform: translate(-20%,-55%); */
}

.new-how-does-it-work-section1-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #45494D;
}

.new-how-does-it-work-section1-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #45494D;
    white-space: "pre-line";
}

.new-how-does-it-work-img-1 {
    /* transform: translateX(15%); */
    max-width: 100%;
    max-height: 100%;
}

.new-how-does-it-work-section2-left-side {
    display: flex;
    align-items: center;
    /* transform: translate(20%,70%); */
}

.new-how-does-it-work-section2-description {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #45494D;
}

.new-how-does-it-work-img3 {
    /* transform: translateX(20%); */
    max-width: 100%;
    max-height: 100%;

}

.new-how-does-it-work-title {
    animation-name: titleAnim;
    animation-duration: 2s;
}

.new-how-does-it-work-section1-left-side-animation {
    animation-name: leftSide;
    animation-duration: 0.7s;
}

.new-how-does-it-work-section1-right-side-animation {
    animation-name: howDoesItWorkRightSide;
    animation-duration: 0.7s;
}

.new-how-does-it-work-section2-left-side-animation {
    animation-name: howDoesItWorkLeftSide;
    animation-duration: 0.7s;
}

.new-how-does-it-work-section2-right-side-animation {
    animation-name: rightSide;
    animation-duration: 0.7s;
}