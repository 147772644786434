
.light-regular-page {
    background-color: #FAFAFA;
}
.light-regular-page-content-wrapper {
    margin-top: 30px;
    width: 100vw;
    min-height: calc(100vh - 300px);
    display: flex;
    height: 100%;
}
.light-regular-page-footer-container {
    margin-top: 90px;
}
