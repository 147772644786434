@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-personality-container {
    width: 100%;
    margin-top: 30px;
}
.instructor-personality-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-personality-options-container {
    width: 100%;
    margin-top: 15px;
}
.instructor-section-sep {
    width: 100%;
    margin-top: 25px;
    background-color: #CACCCF;
}
