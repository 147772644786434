@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.make-reservation-container {
    height: 100%;
    margin-top: 30px;
    display: block;
}
.make-reservation-title-container {
    width: 100%;
    display: flex;
    height: 45px;
    align-items: center;
}
.make-reservation-title {
    text-align: left;
    color: #52575C;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.make-reservation-subtitle {
    text-align: left;
    color: #929598;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    margin-left: 15px;
}
.make-reservation-instructor-list-container {
    width: 100%;
    display: block;
    height: 800px;
    margin-top: 18px;
}
