
@media only screen and (max-width: 720px) {
    .experts-instructors {
        margin-top: 0 !important;
    }
    .experts-instructors-title {
        text-align: center;
        font-size: 36px;
        line-height: 50px;
    }
    .experts-instructors-description {
        text-align: center;
        font-size: 13px;
    }
    .experts-instructors-img {
        /* transform: translateX(5%); */
        width: 100%;
    }
}
