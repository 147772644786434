
@media only screen and (max-width: 720px) {
    .chatroom-icon-container {
        display: none;
    }
    .chatroom-options {
        display: none !important;
    }
    .chatroom-options:hover {
        display: none !important;
    }
    .chatroom-close-icon {
        display: none;
    }
    .chatroom-container {
        width: 100%;
    }
}
