
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .how-does-it-work {
        margin-top: 750px !important;
    }
    .how-does-it-work-title {
        font-size: 50px;
    }
    .how-does-it-work-section1-title {
        font-size: 22px;
    }
    .how-does-it-work-section1-description {
        font-size: 15px;
    }
    .how-does-it-work-section1-right-side {
        width: 90%;
        margin-left: 10%;
        transform: translateY(-15%);
    }
    .how-does-it-work-img {
        width: 100% !important;
    }
    .how-does-it-work-section2-left-side {
        transform: translate(0%,35%);
    }
}
