
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .experts-instructors-title {
        font-size: 40px;
        line-height: 55px;
    }
    .experts-instructors-description {
        font-size: 16px;
        line-height: 22px;
    }
    .experts-instructors-img {
        width: 100% !important; 
    }
}
