
.main-nav-bar-burger-menu {
    border: 0 !important;
    margin-right: -35px !important;
}
.main-nav-bar-burger-menu:focus,
.main-nav-bar-burger-menu:active {
    outline: none;
    box-shadow: none;
}
