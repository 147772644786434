
@media only screen and (max-width: 720px) {
    .student-video-chat-container {
        height: 640px;
        margin-bottom: -70px;
        border-radius: 0;
    }
    .student-video-chat-publisher {
        height: 120px;
        width: 95px;
        left: calc(100% - 110px);
        right: 15px;
        bottom: 75px;
    }
}
