
@media only screen and (max-width: 992px) and (min-width: 726px) {
    .support-navigation-container {
        display: none;
    }
    .dashboard-tablet-nav {
        max-height: 199px;
        height: fit-content;
        display: block;
        margin-top: 10px;
    }
    .dashboard-page-navigation {
        width: 250px;
        margin-left: 50px;
    }
    .open-tablet-dashboard-navigation {
        display: block;
    }
    .support-down-arrow {
        display: block;
    }
    .dashboard-mobile-navigation {
        display: none;
    }
}

@media only screen and (max-width: 767px) and (min-width: 721px) {
    .dashboard-page-content {
        width: 90%;
        display: block;
        float: right;
        margin-top: -44%;
    }
    .dashboard-page-wrapper {
       margin: 0;
       padding: 0;
    }
}
