
@media only screen and (max-width: 725px) {
    .dashboard-page-header{
        margin-top: -13px;
    }
    .dashboard-page-header-title {
        font-size: 36px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .dashboard-page-content {
        margin-top: 15px;
        padding: 30px 16px;
    }
    .dashboard-page-container {
        margin-top: 15px;
    }
    .dashboard-page-footer {
        margin-top: 60px;
    }
    .dashboard-tablet-navigation {
        width: 100%;
    }
    .support-navigation-container {
        display: none;
    }
    .open-dashboard-navigation {
        display: block;
    }
    .dashboard-tablet-nav {
        display: none;
    }
    .support-down-arrow {
        display: block;
    }
    .dashboard-tablet-navigation {
        display: none;
    }
    .dashboard-mobile-navigation {
        width: 100%;
    }
}
