
.chatroom-message-container {
    width: 90%;
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.chatroom-message-circle {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #FFECE8;
}
.chatroom-message-wrapper {
    margin-right: 3px;
    justify-content: flex-end;
}
/* .chatroom-message-content-wrapper {

} */
.chatroom-message-content-container {
    padding: 8px;
    border-radius: 8px;
    background-color: #FFECE8;
    max-width: 185px;
}
.chatroom-message-content-info-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
}
.chatroom-message-content-text {
    text-align: left;
    color: #52575C;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    word-break: break-word;
    white-space: normal;
}

.chatroom-message-time {
    text-align: left;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    font-family: 'Nunito', sans-serif;
}

