@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new_frequently-asked-questions {
    margin-top: 120px;
}

.new_frequently-asked-questions-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
    color: #45494D;
    margin-bottom: 30px !important;
}

.new_frequently-asked-questions-accordion {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2;
    margin-bottom: 10px !important;
}

.new_frequently-asked-questions-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new_frequently-asked-questions-accordion-title {
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 18px;
    transform: translateY(5px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* color: #FF725E; */
}

.new_frequently-asked-questions-collapse {
    display: flex;
    align-items: flex-start !important;
    background-color: #f2f2f2;
}

.new_frequently-ask-question-accordion-toggler:focus,
.new_frequently-ask-question-accordion-toggler:active {
    outline: none !important;
    box-shadow: none !important;
}

.new_frequently-asked-questions-answer {
    text-align: left;
    color: #45494D;
    margin-top: -20px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.new_frequently-asked-questions-btn {
    background-color: #F86F53;
    border: 0;
    padding: 12px 60px;
    border-radius: 40px !important;
    margin-top: 30px !important;
}

.new_frequently-asked-questions-btn:hover {
    background-color: #551103;
}

.new_frequently-asked-questions-container {
    width: 60% !important;
    animation-name: fadeIn;
    animation-duration: 0.7s;
}

.new_frequently-asked-questions-container-start {
    opacity: 100;
}