@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-profile-about-me-container {
    width: 100%;
    margin-top: 30px;
}
.instructor-profile-about-me-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-profile-about-me-desc-container {
    width: 100%;
    margin-top: 15px;
    color: #929598;
    border-radius: 4px;
    box-sizing: border-box;
}
.instructor-profile-about-me-desc {
    text-align: left;
    color: #929598;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}

