@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.input-font-family {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.timeRangeInputWrapper {
    width: 75%;
    height: 40px;
    display: flex;
    border : 1px solid #CACCCF !important;
    border-radius: 4px;
    /* border: 1px solid #6b2e1b;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    background-color: #FFFCFC;
    box-sizing: border-box;
    padding: 12px 14px;
    align-items: center;
}

.timeRangeInputWrapper div{
    border : none;
    color : #777777;
}
.timeRangeInputWrapper div input{
    color : #777777;
}
.react-daterange-picker__button svg
{
    strokeWidth: 2px;
    stroke: #F86F53 !important;
}