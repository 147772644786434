@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.dashboard-page-header {
    background-color: #F86F53;
}
.dashboard-page-fixed-nav-bar {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}
.dashboard-page-header-title {
    text-align: left;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    padding: 35px 0;
    margin-top: 80px;
}
.dashboard-page-container {
    margin-top: 35px;
    min-height: calc(100vh - 435px);
}
.dashboard-page-navigation {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 35px 22px;
    background-color: #F86F53;
    border-radius: 20px;
}
.dashboard-page-navigation-link {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 7px 10px;
    margin-bottom: 5px;
    border-radius: 6px;
}
.dashboard-page-navigation-link.active {
    background-color: #DF4323;
}
.dashboard-page-navigation-link>p {
    text-align: left;
    color: #fff;
    font-size: 600;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.dashboard-page-navigation-link>p:hover {
    color: #551103;
}
.dashboard-page-navigation-link.active>p:hover {
    color: #fff;
}
.dashboard-page-content {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
    background-color: #f2f2f2;
    border-radius: 20px;
}
.dashboard-page-footer {
    margin-top: 90px;
}
.support-down-arrow {
    display: none;
}
