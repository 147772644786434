@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.privacy-page {
    min-height: 100vh;
    width: 100vw;
    background-color: #FAFAFA;
}
.privacy-policy-container {
    margin-top: 30px;
    padding: 0;
}
.privacy-policy-navigation {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 15px;
    background-color: #F86F53;
    border-radius: 20px;
}
.privacy-policy-navigation-link-container {
    padding: 6px 10px;
    border-radius: 6px;
    margin-bottom: 15px;
}
.privacy-policy-navigation-link-container.active {
    background-color: #DF4323;
}
.privacy-policy-navigation-link {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    color: #fff;
    cursor: pointer;
}

.privacy-policy-body {
    border: 0 !important;
    background-color: #F2F2F2;
    border-radius: 20px !important;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
}
.privacy-policy-footer {
    margin-top: 80px;
}
.privacy-policy-tablet-navigation {
    display: none;
}
