.payout-table-wrapper {
    margin: 40px 0 0;
}

.payout-table-wrapper>h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #45494D;
}

.payout-table-wrapper table {
    border: 1px solid #CACCCF;
}

.payout-table-wrapper table thead th {
    background: #EDEDED;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #45494D;
    height: 45px;
    vertical-align: middle;
    text-align: center;
}

.payout-table-wrapper table tbody td {
    color: #1A1A1A;
    font-size: 12px;
    line-height: 20px;
    height: 45px;
    vertical-align: middle;
    border: 1px solid rgba(102, 102, 102, 0.08);
}

.payout-table-wrapper table tfoot th {
    background: #EDEDED;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #45494D;
    height: 45px;
    vertical-align: middle;
    border-top: .13em solid #a4a4a4;
}

.history-records-container {
    width: 100%;
    margin-top: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.history-records-notfound-text {
    margin: 0;
    padding: 0;
    margin-top: 8px;
    text-align: center;
    color: #929598;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
    max-width: 235px;
}