.payout-card {
    background: #F2F2F2;
    border-radius: 20px;
    padding: 20px;
}

.payout-body {
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
}


.payout-danger-alert {
    background: #FFECE8;
    border-radius: 8px;
    display: flex;
    padding: 4px 19px;
}

.payout-danger-alert p {
    margin: 0;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #EE3F46;
    margin-left: 15px;
}

.payout-header h6 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #45494D;
}

.box-header {
    display: flex;
    justify-content: space-between;
}

.payout-box {
    border: 1px solid #CACCCF;
    border-radius: 8px;
    padding: 8px;
    background: #fff;
    margin-bottom: 10px;
}

/* .payouy-b-action .btn {
    background: #5389F8;
    border-radius: 8px;
    border-color: #5389F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 0 25px;
    color: #FAFAFA;
    height: 40px;
} */

.payout-tab-content {
    padding: 16px 0;
}

.payout-tab-content>p {
    font-size: 16px;
    line-height: 22px;
    color: #45494D;
}

.payout-tab .nav.nav-tabs {
    border: none;
}

.payout-tab .nav.nav-tabs a {
    border: none;
    width: 50%;
    background: #CACCCF;
    text-align: center;
    border-radius: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #45494D;
}

.payout-tab {
    margin-top: 15px;
}

.payout-tab .nav.nav-tabs a.active {
    background: #45494D;
    color: #fff;
}

.payout-form-wrapper {
    max-width: 430px;
    width: 100%;
}

.payout-form-wrapper .form-group label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #41566D;
    margin-bottom: 5px;
    font-family: 'Poppins';
}

.payout-form-wrapper .form-group .form-control {
    border: 1px solid #CACCCF;
    border-radius: 4px;
    height: 48px;
}

.payout-form-wrapper .payout-action .btn-primary {
    background: #5389F8;
    border-radius: 8px;
    border-color: #5389F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FAFAFA;
    height: 40px;
    padding: 0 25px;
}

.payimg {
    display: flex;
    align-items: center;
}

.payimg p {
    margin: 0 0px 0 8px;
    font-size: 16px;
    line-height: 22px;
}

.payout-form-wrapper .payout-action .btn-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #45494D;
    margin-left: 20px;
}

.payout-action {
    margin-top: 20px;
}