
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .frequently-asked-questions-container {
        width: 100vw !important;
    }
    .frequently-asked-questions-title {
        font-size: 50px;
    }
    .frequently-asked-questions-btn {
        padding-left: 70px;
        padding-right: 70px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px !important;
    }
}
