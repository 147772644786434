@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-language-and-time-zone-container {
    width: 100%;
    margin-top: 27px;
}
.instructor-language-and-time-zone-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.instructor-language-and-time-zone-choices-container {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.instructor-language-and-time-zone-input-group {
    width: 31%;
    min-width: 197px;
}
.instructor-language-and-time-zone-input-label {
    text-align: left;
    color: #52575C;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
