@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.payment-adress {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
.adress-form-container {
    width: 100vw;
    max-width: 550px;
    height: fit-content;
    box-sizing: border-box;
    padding: 45px 25px;
    background-color: #fff;
    border-radius: 8px;
}
.adress-payment-title {
    text-align: left;
    color: #F86F53;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.adress-form {
    width: 100%;
    margin-top: 25px;
}
.form-adress-input-container {
    margin-top: 15px;
}
.form-adress-info-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form-adress-input {
    width: 48%;
    margin-right: 1%;
}
.adress-form-btns {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
