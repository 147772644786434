.chatdialog-container {
    width: 400px;
    /* background-color: blanchedalmond;
    display: flex;
    height: 500px;
    width: 300px;
    justify-content: flex-end;
    position: sticky;
    bottom: 20px; */
}

.chat {
    margin-top: auto;
    margin-bottom: auto;
}

.chat-card {
    height: 500px;
    border-radius: 15px;
}

.contacts_body {
    padding: 0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
}

.msg_card_body {
    overflow-y: auto;
}

.chat-card-header {
    display: flex;
    background: #E9ECEF;
    border-radius: 5px 5px 0px 0px;
    /* border-radius: 15px 15px 0 0 !important; */
    border-bottom: 0 !important;
}

.chat-card-footer {
    display: flex;
    margin: 10px 16px 16px 16px;
    border: #CACCCF 1px solid;
    border-radius: 15px 15px !important;
    padding: 8px;
}

.chat-card-footer-text {
    width: 100%;
}

.container {
    align-content: center;
}

.search {
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
}

.search:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.type_msg {
    color:#929598;
    border: 0 !important;
    width: 100%;
    height: 60px !important;
    overflow-y: auto;
}

.type_msg:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.attach_btn {
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.send_btn {
    width: 24px;
    height: 24px;
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.search_btn {
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.contacts {
    list-style: none;
    padding: 0;
}

.contacts li {
    width: 100% !important;
    padding: 5px 10px;
    margin-bottom: 15px !important;
}

.chat-active {
    background-color: rgba(0, 0, 0, 0.3);
}

.user_img {
    height: 100%;
    width: 100%;
}

.user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
}

.img_cont {
    position: relative;
    height: 50px;
    width: 50px;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.img_cont_msg {
    height: 40px;
    width: 40px;
}

.online_icon {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #4cd137;
    border-radius: 50%;
    bottom: 0.2em;
    right: 0.4em;
    border: 1.5px solid white;
}

.offline {
    background-color: #c23616 !important;
}

.user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
}

.chat-card-header-close {
    align-self: center;
    margin-left: auto;
    margin-right: 16px;
    align-items: center;
}
.chat-card-footer-send-button
{
    margin-left: 8px;
    margin-right: 8px;
    align-self: center;
    align-items: center;
}


.user_info span {
    font-size: 20px;
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
}

.user_info p {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
}

.video_cam {
    margin-left: 50px;
    margin-top: 5px;
}

.video_cam span {
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
}

.msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    border-radius: 3px;
    background-color: #FFECE8;
    padding: 10px;
    position: relative;
}

.msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    border-radius: 3px;
    background-color: #F2F2F2;
    padding: 10px;
    position: relative;
}

.msg_time {
    position: absolute;
    left: 0;
    bottom: -15px;
    color: #CACCCF;
    font-size: 10px;
}

.msg_time_send {
    position: absolute;
    right: 0;
    bottom: -15px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
}

.msg_head {
    position: relative;
}

#action_menu_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.action_menu {
    z-index: 1;
    position: absolute;
    padding: 15px 0;

    color: white;
    border-radius: 15px;
    top: 30px;
    right: 15px;
    display: none;
}

.action_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.action_menu ul li {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 5px;
}

.action_menu ul li i {
    padding-right: 10px;

}

.action_menu ul li:hover {
    cursor: pointer;
}

@media(max-width: 576px) {
    .contacts_card {
        margin-bottom: 15px !important;
    }
}