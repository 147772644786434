
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
    .instructors-cards-container {
        height: 1600px;
    }
}

@media only screen and (max-width: 1024px) {
    .instructors-cards-container {
        margin-top: 30px;
    }
    .instructors-cards-container {
        height: 1000px;
    }
} 

@media only screen and (max-width: 990px) {
    .instructors-cards-container {
        height: 1900px;
    }
}
