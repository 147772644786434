
@media only screen and (max-width: 720px) {
    .frequently-asked-questions-container {
        width: 100vw !important;
        margin-top: -70px !important;
    }
    .frequently-asked-questions-title {
        font-size: 36px;
        max-width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .frequently-asked-questions-accordion-title,
    .frequently-asked-questions-answer {
        font-size: 13px;
    }
    .frequently-asked-questions-btn {
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px !important;
    }
}
