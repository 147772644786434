@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new-tutor-message-banner {
    margin-top: 200px;
}

.new-tutor-message-banner-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 13px;
}

.new-tutor-message-banner-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #45494D;
    margin-bottom: 31px;
}

.new-tutor-message-banner-button {
    background: #000000;
    border-radius: 8px;
}

.new-tutor-message-banner-button-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.new-tutor-message-banner-container {
    background: #FAFAFA;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 24px;
}