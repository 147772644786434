.callingModal .modal-header {
    background: #fff;
    border: none;
    justify-content: flex-end;
}

.callingModal .modal-header img {
    width: 25px;
    height: 25px;
}

.callingBody {
    text-align: center;
    padding: 0 15px;
}

.missIcon {
    background: #45494D;
    position: absolute;
    right: -25px;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    align-items: center;
    bottom: 0;
}

.caller-img {
    position: relative;
    width: 100%;
    max-width: 120px;
    margin: 0 auto 15px;
}

.caller-content h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #45494D;
}

.caller-content p {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #45494D;
}

.callingModal .modal-dialog {
    border-radius: 8px;
}

.callingFooter {
    display: flex;
    justify-content: center;
}

.callingFooter.reject .button-wrapper button {
    background: transparent;
    border: none;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;

    /* Cizgi Rengi */
    color: #CACCCF;
}

.reject-button button {
    background: #EE3F46;
    border: none;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.callingFooter.reject {
    margin-top: 60px;
    justify-content: space-around;
}

.reject-button {
    position: relative;
    top: -30px;
}

.callingFooter.accept {
    justify-content: space-around;
    align-items: center;
    margin-top: 35px;
}
.callingFooter.accept h6{
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    margin-top: 6px;
    color:#888888
}

.callingFooter.accept .button-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
}

.callingFooter.accept .button-wrapper buton {
    width: 100%;
}

.callingFooter.accept .button-wrapper button {
    width: 100%;
    background: #F2F2F2;
    border-radius: 16px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #45494D;
    height: 40px;
}

.callingFooter.accept .accept-button button {
    background: #00D21D;
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 40px;
}
.callingFooter.accept .rejectcall-button button {
    background: #EE3F46;
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 40px;
}

.callingFooter.accept .accept-button button img {
    transform: rotate(-145deg);
}

.callingFooter.accept .button-wrapper:nth-child(1) {
    right: -20px;
}

.callingFooter.accept .button-wrapper:nth-child(1) button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}



.callingFooter.accept .button-wrapper:last-child {
    left: -20px;
}

.callingFooter.accept .button-wrapper:last-child button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
}

.accept-button {
    position: relative;
    z-index: 9;
}

.callingFooter.content {
    float: left;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
}

.callingFooter.content p.danger {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #F86F53;
    margin-bottom: 0;
}

.callingFooter.content .grey {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #CACCCF;
}