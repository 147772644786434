@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.subscription-detail-plan {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    /* padding: 30px; */
    background-color: #f2f2f2;
    border-radius: 20px;
}
.subscription-detail-plan-title {
    text-align: left;
    color: #45494D;
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}
.subscription-details-container {
    margin-top: 15px;
}
.subscription-plan {
    width: 100%;
}
.subscription-plan-wrapper {
    width: 95%;
    height: fit-content;
    box-sizing: border-box;
    padding: 15px 18px;
    background-color: #e6e6e6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subscription-plan-title {
    text-align: center;
    color: #45494D;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.subscription-plan-subtitle {
    text-align: center;
    color: #45494D;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}
.subscription-plan-second-section {
    margin-top: 60px;
}
.subscription-details-my-payments {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #f2f2f2;
    border-radius: 20px;
}
.subscription-details-my-payments-title {
    text-align: center;
    color: #45494D;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 24px;
}
.subscription-details-my-payments-list {
    height: 85%;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 40px;
}
.subscription-details-my-payments-total {
    height: 15%;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 40px;
    background-color: #e6e6e6;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
}
.subscription-details-my-payments-total-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(7px);
}
.subscription-details-my-payments-total-title {
    text-align: left;
    color: #45494D;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
}
.subscription-details-my-payments-total-content {
    text-align: right;
    color: #45494D;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
