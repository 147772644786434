.reservations-calendar-container {
    width: 100%;
    margin-top: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.reservations-calendar-container-text {
    margin: 0;
    padding: 0;
    margin-top: 8px;
    text-align: center;
    color: #929598;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    line-height: 19px;
    max-width: 235px;
}
