
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.reservation-top-bar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.reservation-call-duration {
    width: 500px;
    height: 85px;
}
.reservation-call-duration-title {
    text-align: left;
    color: #41566D;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}
.reservation-options-container {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 8px;
}
.reservation-options-container button {
    height: 40px;
    margin-right: 10px;
}
.reservation-schedule {
    height: 83px;
    width: 460px;
}
.reservation-schedule-title {
    text-align: left;
    color: #41566D;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}
.reservation-schedule-selectors {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-top: 8px;
}
/* .date-picker input {
    width: 100%;
} */
.date-picker input {
    width: 100%;
     background: none;
}

.reservation-schedule-calendar {
    /* min-width: 220px; */
    height: 40px;
    /* width: 160px; */
    display: flex;
    align-items: center;
    justify-content: left;
    /* border: 1px solid #929598; */
    /* border-radius: 4px; */
    /* box-sizing: border-box; */
    /* padding: 0 12px; */
}
.reservation-schedule-calendar>p {
    text-align: left;
    color: #929598;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.calendar-image {
    margin-left: 15px;
}
.reservation-schedule-seperator {
    width: 18px;
    background-color: #929598;
}
.reservation-schedule-time {
    height: 40px;
    width: 115px;
}
.reservation-view-btn-container {
    height: 40px;
    margin-left: 15px;
}

.reservation-view-btn-container button{
    height: 40px;
    padding: 5px 27px !important;
}

