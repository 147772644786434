@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.preferances-title {
    text-align: left;
    color: #45494D;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.preferances-settings-container {
    margin-top: 30px;
}
.preferences-sep {
    background-color: #CACCCF !important;
    margin-bottom: 30px;
}
.preferances-language-location {
    margin-top: 30px;
}
.preferences-save-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 720px) {
    .preferences-save-button {
        width: 100%;
    }
}
