
.instructor-notifications-settings-footer {
    width: 100%;
    margin-top: 30px;
    height: 60px;
}
.instructor-notifications-settings-footer-container {
    width: 350px;
    height: 60px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
