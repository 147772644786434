
@media only screen and (max-width: 720px) {
    .subscription-detail-plan {
        padding: 35px 15px;
        margin-bottom: 30px;
    }
    .subscription-plan-wrapper {
        margin-left: auto;
        margin-right: auto;
    }
    .subscription-plan {
        margin-bottom: 30px;
    }
    .subscription-plan-btn {
        width: 100% !important;
    }
    .subscription-plan-second-section {
        margin-top: 30px;
    }
    .subscription-details-my-payments {
        min-height: 600px;
    }
}
