
@media only screen and (max-width: 960px) and (min-width: 720px) {
    .packages-total-bill,
    .package-total-bill-purchase-btn-container,
    .package-total-bill-link {
        width: 90vw;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }
    .packages-total-bill {
        margin-top: 60px;
        box-shadow: none !important;
    }
}