
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .support-questions-title {
        font-size: 24px;
    }
    .category-title-1 {
        font-size: 22px;
    }
    .support-question-title {
        font-size: 14px;
    }
    .support-question-title,
    .support-question-parag {
        padding: 16px !important;
    }
}
