@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new-subscriptions-packages {
    background: linear-gradient(88.62deg, #001E33 7.84%, #004473 90.69%);
    margin-top: 120px;
}
.new-subscriptions-packages-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.new-subscriptions-packages-left-side {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    box-sizing: border-box;
    /* padding-top: 50px;
    transform: translateY(-55px); */
}
.new-subscription-packages-title {
    text-align: left;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 36px;
}
.new-subscriptions-packages-descripton {
    text-align: left;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    margin-top: 1px;
}

.new-subscription-package-img {
    width: 100% !important;
    animation-name: PackagesImageFadeIn;
    animation-duration: 0.7s;
    transform: translateY(-45px);
}
.new-subscriptions-packages-image-start {
    opacity: 100;
}
.new-subscriptions-packages-left-side-animation {
    animation-name: SubscriptionLeftSide;
    animation-duration: 0.7s;
}
.new-subscriptions-packages-left-side-start {
    opacity: 100;
}
