
@media only screen and (max-width: 720px) {
    .login-form-container {
        padding: 15px 0px !important;
    }
    .login-form-title {
        font-size: 24px;
    }
    .login-form-btns {
        width: 100% !important;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .login-forget-password {
        cursor: pointer;
        margin-top: -35px;
    }
    .not-a-member {
        text-align: center;
    }
    .form-links {
        justify-content: initial;
    }
}
