@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.support-instructor {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: var(--black);
    font-size: 32px;
}
.instructors-support-link {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.instructors-support-link:hover,
.instructors-support-link:active,
.instructors-support-link:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
}
.instructors-support-text p {
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #000;
}
.support-questions {
    margin-top: 30px;
}
