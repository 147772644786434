@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.payment-form {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 30px 24px;
    background-color: #fff;
    border-radius: 25px;
}
.payment-form-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payment-form-title {
    text-align: left;
    color: #F86F53;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.payment-methods {
    display: flex;
}
.payment-method-icon {
    margin-left: 5px;
}
.payment-form-card-number {
    margin-top: 30px;
}
.credit-card-info {
    width: 100%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.credit-card-holder {
    width: 45%;
}
.credit-card-extra-info {
    width: 25%;
}
.payment-form-sep {
    background-color: #CACCCF;
    margin: 30px 0;
}
.billing-adress-info-container {
    margin-bottom: 45px;
}
.billing-adress-info>h4 {
    text-align: left;
    color: #52575C;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
}
.billing-adress-info-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 15px;
    border: 1px solid #CACCCF;
    border-radius: 5px;
}
.billing-adress-info-text {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #929598;
}
.billing-adress-edit {
    height: 100%;
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #CACCCF;
}
.billing-adress-edit>p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #52575C;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
}
.billing-adress-edit>p:hover {
    color: #F86F53;
}
