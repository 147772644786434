
.home {
    height: fit-content;
    min-height: 100vh;
    width: 100vw;
    background-color: #fafafa;
}

.super_hero
{
    background-image: url(../../assets/Home/hero.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 750px;
    height: fit-content;
}

a:hover{
    cursor: pointer;
}
.ring{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 0;
}