
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .banner {
        margin-top: 10px !important;
    }
    .home-banner-title {
        font-size: 28px;
    }
    .banner-description {
        font-size: 16px;
    }
    .banner-img {
        width: 100%;
    }
}
