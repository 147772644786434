@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.training-cat-wrapper {
    display: flex;
    justify-content: center;
}
.training-cat-container {
    width: fit-content !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 400px);
    overflow: hidden;
}
.training-category-card {
    width: 210px;
    height: 275px;
    box-sizing: border-box;
    padding: 21px 11px;
    border: 1px solid #CACCCF;
    border-radius: 10px;
    display: block;
    float: left;
    margin-right: 30px;
    cursor: pointer;
    margin-bottom: 30px;
}
.training-category-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.training-category-card-title {
    text-align: center;
    color: #52575C;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
}
.training-category-card-title-third {
    margin-top: -25px;
    margin-bottom: 50px;
}
