@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.change-password-title {
    text-align: left;
    color: #45494D;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
}
.change-password-inputs-container {
    margin-top: 30px;
    width: 50%;
}
.change-password-btn-container {
    margin-top: 30px;
}
