
.instructor-profile-footer {
    width: 100%;
    margin-top: 30px;
    height: 60px;
}
.instructor-profile-footer-container {
    width: 420px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
}
