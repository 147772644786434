
.payment {
    min-height: 100vh;
    background-color: #fafafa;
}
.payment-container {
    margin-top: 30px;
}
.payment-footer-container {
    margin-top: 30px;
}

.go-back-payment {
    display: flex;
    height: 45px;
    width: fit-content;
    padding: 0;
    padding-right: 25px;
    padding-left: 15px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 30px;
}
.go-back-payment p {
    margin: 0;
    padding: 0;
    text-align: left;
    color: #52575C;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
.go-back-payment:hover {
    transition: background-color 0.2s ease;
    background-color: #F86F53;
}
.go-back-payment:hover p {
    color: #fff;
}

