.top-bar-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-bar-status-btns {
    display: flex;
    margin-top: 15px;
}

.instructors-search-bar {
    width: 350px;
    height: 40px;
}


.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 24px !important;
    display: inline-block;
    vertical-align: middle;
}

.checkbox-wrapper {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #777777;
}

.top-bar-status-btn-container .custom-dropdown {
    min-width: 260px;
    background: #FFFFFF;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 15%);
    border-radius: 4px;
    padding: 24px 24px;
    border: none;
}

.styled-checkbox+label:before {
    content: "";
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background: #FFFCFC;
    border: 1px solid #CACCCF;
    box-sizing: border-box;
}

.styled-checkbox:hover+label:before {
    background: #f35429;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #f35429;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    /* position: absolute;
    left: 3px;
    top: 7px; */
    display: inline-block;
    vertical-align: middle;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.top-bar-status-btn-container .dropdown-toggle::after {
    display: none;
}

.clearFilter {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #777777;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #CACCCF;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.clearFilter .cross {
    color: #929598;
    font-weight: 900;
    font-size: 30px;
    margin-right: 10px;
    display: inline-block;
    margin-top: -5px;
}

.status-wrapper:hover {
    background: #e1e1e1;
}

.staus-wrapper .custom-dropdown {
    min-width: 100%;
}

.status-wrapper {
    background: #E9E9E9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #45494D;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 0 0 20px;
    width: 100%;
    cursor: pointer;
}

.circle.all {
    background: #45494D;
    height: 14px;
    width: 14px;
    border-radius: 20px;
    margin-right: 10px;
}

.circle.online {
    background: #00D21D;
    height: 14px;
    width: 14px;
    border-radius: 20px;
    margin-right: 10px;
}

.circle.busy {
    background: #EE3F46;
    height: 14px;
    width: 14px;
    border-radius: 20px;
    margin-right: 10px;
}

.circle.offline {
    background: #aaaaaa;
    height: 14px;
    width: 14px;
    border-radius: 20px;
    margin-right: 10px;
}