
.content {
    display: flex;
    align-items: center;
}

.icon
{
    width: 400px;
    margin-right: 30px;
}
