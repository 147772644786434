
@media only screen and (max-width: 720px) {
    .signup-form-container {
        padding: 15px 0px !important;
    }
    .signup-form-title {
        font-size: 24px;
    }
    .signup-form-btns {
        width: 100% !important;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .signup-forget-password {
        margin-top: -35px;
    }
    .not-a-member {
        text-align: center;
    }
    .form-links {
        justify-content: initial;
    }
    .checkbox-label {
        font-size: 14px;
        font-weight: 400;
    }
}
