@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.new_students-reviews {
    margin-top: 140px;
}
.new_students-reviews-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
    color: #45494D;
}
.new_students-reviews-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;   
}
.new_students-reviews-card-item {
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    /* border-radius: 50px;
    box-sizing: border-box; */
    padding: 15px 15px;
}       
.new_students-reviews-card-item-top {
    width: 100%;
    /* transform: translate(-1%,-55%);  */
    margin-bottom: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.new_students-reviews-card-item-img {
    content: url("../../assets/quotes.png");
}
.new_students-reviews-cards-item-description {
    max-width: 100%;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;  
    margin-bottom: 30px;
    color: #45494D;
}
.new_students-reviews-cards-item-owner {
    display: flex;
    margin-bottom: 15px;
}
.new_students-reviews-cards-item-owner-name-container {
    margin-left: 15px;
    margin-top: 5px;
}
.new_students-reviews-cards-item-owner-name {
    text-align: left;
    font-family: 'Nunito', sans-serif;
    color: #5389F8;
    font-size: 18px;
    line-height: 25px;
}

/* .new_students-reviews-card-item:hover {
    background-color: #FDD9D1;
    border-color: #FDD9D1;
}
.new_students-reviews-card-item:hover .new_students-reviews-card-item-img {
    content: url("../../assets/quotes-orange.png");
}
.new_students-reviews-card-item:hover .new_students-reviews-cards-item-description {
    transition: color 0.3s ease;
    color: #F86F53;
}
.new_students-reviews-card-item:hover .new_students-reviews-cards-item-owner-name {
    transition: color 0.3 ease;
    color: #F86F53;
} */

.new_students-reviews-container {
    animation-name: fadeIn;
    animation-duration: 0.7s;
}
.new_students-reviews-container-start {
    opacity: 100;
}
