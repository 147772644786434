
.subscribers {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    display: flex;
}
.subscriber * {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    display: flex;
}
.OT_bar.OT_edge-bar-item.OT_mode-auto {
    display: none !important;
}
.OT_bar.OT_edge-bar-item.OT_mode-auto:hover {
    display: none !important;
}
.OT_video-disabled-indicator.OT_mode-auto.OT_active {
    display: none !important;
}
