
@media only screen and (max-width: 960px) and (min-width: 721px) {
    .update-payment-informations {
        margin-top: 60px;
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }
    .update-payment-btn {
        width: 450px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .go-pack-update-payment {
        margin-left: auto;
        margin-right: auto;
    }
}
