@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.instructor-notifications-settings-container {
    width: 100%;
}
.instructor-notifications-settings-title {
    text-align: left;
    color: #52575C;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}
.instructor-notifications-settings-wrapper {
    width: 70%;
    margin-top: 42px;
}
.instructor-notifications-settings-sep {
    width: 100%;
    background-color: #CACCCF;
    margin-top: 30px;
}
