@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

.reservation {
    width: 100vw;
    min-height: 100vh;
    background-color: #fafafa;
}
.reservation-nav-bar-container {
    background-color: #fafafa;
}
.reservation-banner {
    width: 100vw;
    height: 180px;
    background-color: #F86F53;
}
.reservation-banner-title {
    text-align: left;
    color: #fff;
    font-size: 36px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}
.reservation-sections {
    display: flex;
    align-items: flex-end;
    margin-top: -51px;
}
.reservation-section {
    width: fit-content;
    background-color: #f2f2f2;
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid #FDD9D1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.reservation-section-title {
    text-align: center;
    color: var(--black);
    font-size: 19px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    transform: translateY(5px);
}
.reservation-section-active {
    background-color: #fafafa;
    padding: 10px 20px;
    border-bottom: 1px solid #fafafa;
}
.reservation-container {
    min-height: calc(100vh - 300px);
}
