@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@media only screen and (max-width: 720px) {
    
    .mobile-bottom {
        width: 100%;
        height: 275px;
        background: linear-gradient(360deg, rgba(38, 38, 38, 0.5) 0%, rgba(38, 38, 38, 0) 100%);;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .mobile-bottom-input-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .mobile-bottom-input-wrapper {
        width: 90%;
        height: 45px;
        border-radius: 30px;
        border: 1px solid #FAFAFA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 24px;
        margin-right: 8px;
    }
    .mobile-bottom-messages {
        margin-bottom: 22px;
    }
    .mobile-bottom-message-text {
        text-align: left;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        line-height: 18px;
        font-family: 'Nunito', sans-serif;
        max-width: 60%;
        margin-top: 15px;
    }
}
