@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.login-form {
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
}
.login-form-container {
    width: 90vw;
    max-width: 800px;
    padding: 30px 140px;
    border-radius: 24px;
    border: 0;
    box-sizing: border-box;
}
.login-form-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--black)
}
.login-form-subtitle {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.login-form-btns {
    width: 450px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
.login-form-fb-btn {
    padding: 14px 25px;
    background-color: #3D5288;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border-radius: 50px;
    border: 0;
}
.login-form-fb-btn:hover {
    background-color: #152346;
}
.login-form-google-btn {
    padding: 14px 25px;
    background-color: #fafafa;
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid var(--black);
    border-radius: 50px;
}
.login-form-google-btn:hover {
    background-color: #EAEAEA;
    border: 1px solid var(--black);
    color: var(--black);
}
.login-sep {
    width: 100%;
    height: 1px;
    background-color: #9a9a9a;
}
.login-sep-title {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 10px;
    background-color: #fff !important;
    margin-top: -14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.login-label {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #41566D;
}
.checkbox-label {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #677294;
}

.not-a-member {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13.5px;
    color: var(--black)
}
.form-links {
    display: flex;
    justify-content: space-between;
}
.subscribe-now {
    color: #F86F53;
    cursor: pointer;
    margin-left: 10px;
}
.login-forget-password {
    cursor: pointer;
}