
@media only screen and (max-width: 720px) {
    .light-regular-page-container {
        width: 100vw !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .light-regular-page-footer-container {
        margin-top: 70px;
    }
}
