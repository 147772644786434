
@media only screen and (max-width: 720px) {
    .upgrade-subscription {
        height: 100% !important;
        overflow-x: scroll !important;
        align-items: flex-start;
        padding-top: 260px;
    }
    .upgrade-subscription-modal {
        width: 95vw;
    }
    .subscription-modal-package {
        margin-bottom: 15px;
    }
    .automatic-renewel-cancelation {
        margin-top: 3px !important;
    }
    .automatic-renewel-btn {
        font-size: 14px;
    }
    .automatic-renewel-cancelled {
        margin-top: 1px !important;
        margin-bottom: 15px;
    }
}
