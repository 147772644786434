@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.student-video-chat-header {
    height: 32px;
    width: fit-content;
    position: absolute;
    left: 24px;
    top: 24px;
    display: flex;
    align-items: center;
    z-index: 4;
}
.student-video-chat-header-name {
    text-align: left;
    color: #fff;
    font-size: 19px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin-right: 14px;
}
.student-video-chat-time-wrapper {
    padding: 5px 18px;
    background-color: #F86F53;
    border-radius: 8px;
    margin-left: 35px;
    display: flex;
    align-items: center;
}
.student-video-chat-time {
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    margin-left: 7px;
}
