
.publisher {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}
.publisher * {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}
.OT_edge-bar-item.OT_mute.OT_mode-auto {
    display: none !important;
}
