@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.student-video-chat-container {
    width: 100%;
    height: 80vh;
    background-color: #000;
    border-radius: 8px;
}
.student-video-chat-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.student-video-chat-subscriber {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border-radius: 8px;
}
.student-video-chat-subscriber-waiting-opponent {
    height: 50%;
    width: 50%;
    margin: auto;
    text-align: center;
    z-index: 3;
    border-radius: 8px;
}
.student-video-chat-publisher {
    height: 132px;
    width: 233px;
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 4;
    border-radius: 8px;
    border: 2px solid #fff;
}
