
@media only screen and (max-width: 720px) {
    footer {
        height: 250px !important;
    }
    .footer-bottom {
        display: flex;
        flex-direction: column-reverse;
    }
    .footer-top {
        height: 80px !important;
    }
    .footer-top-container {
        width: 100vw;
        display: flex;
        justify-content: space-around;
    }
    .footer-top-link {
        font-size: 14px;
    }
    .footer-bottom-social-media {
        width: fit-content;
        margin-top: 10px !important;
        position: relative;
        left: 50%;
        transform: translateX(-55%);
    }
    .footer-bottom-centered-links {
        width: 95vw;
        display: flex;
        justify-content: space-around;
        margin-left: -15px !important;
        margin-top: 25px;
    }    
    .footer-bottom>p {
        text-align: center;
        font-size: 15px;
    }
    .footer-bottom-centered-links>p {
        font-size: 13px;
    }
    .footer-bottom {
        height: 30%;
        margin-top: -40px !important;
    }
}
