
@media only screen and (max-width: 720px) {
    .support-instructor {
        font-size: 22px;
    }
    .support-questions {
        margin-top: 15px;
    }
    .instructors-support-link,
    .instructors-support-text p {
        font-size: 14px;
    }
}
