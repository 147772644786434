.historyContent .name {
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    color: #52575C;
}

.historyContent p {
    font-size: 19px;
    line-height: 26px;
    color: #45494D;
    margin: 0 0 3px 0;
}

.historyHeader img {
    width: 25px;
    height: 25px;
}

.historyHeader h4 {
    font-size: 19px;
    line-height: 26px;
    color: #777777;
    font-weight: bold;
    margin: 0;
}

.historyHeader {
    display: flex;
    align-items: center;
}

.historyHeader .modal-title.h4 img {
    width: 20px !important;
}

.historyHeader img {
    width: 25px;
    height: 25px;
}

.historyTab .nav.nav-tabs a {
    background: #5389F8;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    text-align: center;
    color: #FAFAFA;
    border-radius: 0;
    border: none;
    padding: 12px 20px;
}

.historyTab {
    position: relative;
    padding: 15px;
}

.historyTab .nav.nav-tabs a:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.historyTab .nav.nav-tabs a:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.historyTab .nav.nav-tabs a.active {
    background: #426FCD;
}

.note-wrapper h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #777777;
    margin-bottom: 3px;
}

.note-inner {
    /* background: #F2F2F2; */
    border-radius: 3px;
    /* padding: 15px; */
}

.note-inner p {
    font-size: 13px;
    line-height: 18px;
    color: #45494D;
}

.note-wrapper {
    margin: 10px 0 0;
}

.historyContent p span.badge {
    background: #FDD9D1;
    border-radius: 3px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #F86F53;
    margin-left: 10px;
}


.historyModal .modal-dialog {
    max-width: 750px;
}

.history-keyValue ul {
    padding: 0;
    list-style: none;
    float: left;
    margin-top: 15px;
    margin-right: -10px;
    margin-left: -10px;
}

.history-keyValue ul li {
    width: 50%;
    float: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.history-keyValue ul li span.key {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #777777;
}

.history-keyValue ul li span.value select {
    color: #777777;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #CACCCF;
    border-radius: 3px;
    outline: none;
    padding: 2px;
}

.videoContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.noHistoryFound {
    display: flex;
    width: 315px;
    height: 400px;
    align-items: center;
    color : #777777;
    align-content: center;
}
.noHistoryFound span{
    align-self: center;
    align-content: center;
    align-items: center;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.chat-Icons {
    position: absolute;
    z-index: 9999999999;
    right: 10px;
    top: 10px;
    height: 90%;
}

.chat-room-container {
   
    right: 10px;
    top: 10px;
    border: none;
    height: 100%;
    width: 48px;
    border-radius: 50px;
    text-align: center;
}

.chat-Icons button img {
    width: 100%;
}

.chat-Icons button {
    border: none;
    height: 48px;
    width: 48px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
}

.historyTab nav.nav.nav-tabs {
    border: none;
    margin-bottom: 15px;
}

.c-r-bt {
    position: absolute;
    top: 20px;
    right: 15px;
}

.saveButton {
    align-items: center;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
}
/* .saveButton button {
    background: #EE3F46;
    border-radius: 8px;
    border-color: #EE3F46;
} */

.c-r-bt button {
    background: #EE3F46;
    border-radius: 8px;
    border-color: #EE3F46;
}